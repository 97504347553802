import { useContext, useEffect, useMemo, useState } from "react"
import { IDeal } from "../../../../typings"
import { useAsyncEffect } from "ahooks";
import { request } from "../../../../utils";
import { Spin, Tag, Timeline } from "@douyinfe/semi-ui";
import dayjs from "dayjs";
import FlexContainer from "../../../../components/FlexContainer";
import { AppContext } from "../../../../App";
import { IconMore } from "@douyinfe/semi-icons";
import './index.less'

import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime);

interface IPriceHistoryPaneProps {
    platform: string;
    sku_id: string;
}

const PriceHistoryPane = ({ platform, sku_id }: IPriceHistoryPaneProps) => {

    const { isMobile, COLOR_MAP, isDarkMode } = useContext(AppContext)

    const [loading, setLoading] = useState(true)
    const [priceHistoryList, setPriceHistoryList] = useState<IDeal[]>([])

    useAsyncEffect(async () => {
        setLoading(true)

        const respData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_${platform.replace(/-/g, '_')}_product_price_change WHERE sku_id = "${sku_id}"`
                ]
            }
        })

        setPriceHistoryList(respData.reverse())
        setLoading(false)
    }, [])

    useEffect(() => {
        if (priceHistoryList.length) {
            const elList = Array.from(document.querySelectorAll('.rect'))
            elList.forEach((element: any) => {
                let width = element.getAttribute('data-width');
                element.style.setProperty('--dynamic-width', width);
                element.style.width = width; // Set the width directly in the inline style
            });
        }
    }, [priceHistoryList])

    const lowestPrice = useMemo(() => {
        return Math.min(...priceHistoryList.map(item => item.new_price), 10000)
    }, [priceHistoryList])

    const highestPrice = useMemo(() => {
        return Math.max(...priceHistoryList.map(item => item.new_price), 0)
    }, [priceHistoryList])

    if (loading) {
        return (
            <FlexContainer style={{ width: '100%', padding: '32px 0 16px' }} justifyContent="center">
                <Spin spinning size="large" />
            </FlexContainer>
        )
    }

    if (priceHistoryList.length === 0) {
        return (
            <FlexContainer style={{ width: '100%', padding: '32px 0 16px', color: 'grey' }} justifyContent="center">
                <div className="font-weight-bold">Oops! No price history yet.</div>
            </FlexContainer>
        )
    }

    return (
        <div style={{ paddingTop: 8, paddingLeft: isMobile ? 10 : 0, marginBottom: -24 }}>
            <Timeline className="price-history-pane-wrapper" style={{ width: 'calc(100% - 32px)' }}>
                {priceHistoryList.map((history, idx) => {
                    const dayText = idx === priceHistoryList.length - 1 ? 0 : dayjs(history.generated_at).from(dayjs(priceHistoryList[idx+1].generated_at), true)
                    const widthPercent = (((history.new_price - lowestPrice) / ((highestPrice - lowestPrice) || 1)) * 60 + 40) + '%'

                    return (
                        <Timeline.Item key={`timeline-item-${idx+1}`} time={(
                            <FlexContainer flexDirection="column" alignItems="flex-start">
                                <span className="font-weight-bold">
                                    {dayjs(history.generated_at).format('DD/MM/YYYY')}
                                </span>

                                {!!dayText && (
                                    <FlexContainer flexDirection="column" alignItems="center" style={{ marginTop: 8, marginBottom: -15, marginLeft: 8 }} >
                                        <span className="font-weight-regular" style={{ writingMode: 'vertical-lr' }}>...</span>
                                        <span className="font-weight-bold" style={{ fontSize: 10, margin: '2px 0 2px' }}>{dayText}</span>
                                        <span className="font-weight-regular" style={{ writingMode: 'vertical-lr' }}>...</span>
                                    </FlexContainer>
                                )}
                            </FlexContainer>
                            
                        )}>
                            <FlexContainer alignItems="center" gap="8px" style={{ position: 'relative' }}>
                                <span style={{ color: idx === 0 ? COLOR_MAP[platform] : 'grey', letterSpacing: -1, fontSize: 20, fontWeight: 700 }}>
                                    ${history.new_price}
                                </span>

                                {lowestPrice === history.new_price && (
                                    <Tag style={{ backgroundColor: COLOR_MAP[platform], color: 'white', padding: '0px 6px' }} shape="circle">
                                        <span className="font-weight-bold">Low</span>
                                    </Tag>
                                )}

                                <div className="rect" data-width={widthPercent} style={{ position: 'absolute', height: 48, top: -5, background: `${COLOR_MAP[platform]}${isDarkMode ? '32' : '16'}`, borderRadius: 4, left: -8 }} />
                            </FlexContainer>
                        </Timeline.Item>
                    )
                })}
            </Timeline>
        </div>
    )
}

export default PriceHistoryPane