import { memo, useContext, useEffect, useMemo, useState } from "react"
import SubHeader from "../../components/SubHeader"
import { Button, Card, Checkbox, Image, Input, Modal, Select, SideSheet, Spin, Table, Tag } from "@douyinfe/semi-ui"
import App, { AppContext } from "../../App"
import { IGiftCardPromotion } from "../../typings"
import { useAsyncEffect } from "ahooks"
import { renderText, request } from "../../utils"
import './index.less'
import { IconFile, IconFilter, IconHelpCircle, IconImage, IconInfoCircle, IconLive, IconSearch } from "@douyinfe/semi-icons"
import FlexContainer from "../../components/FlexContainer"

import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import relativeTime from "dayjs/plugin/relativeTime"
import BrandExplorer from "./components/BrandExplorer"
import GiftCardPromotionDetailBody from "./components/GiftCardPromotionDetailBody"
import { useNavigate } from "react-router-dom"
import TourContainer from "../../components/TourContainer"

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(relativeTime)

export const GiftCardPromotionStatusRender = ({ record }: { record: IGiftCardPromotion }) => {
    const { isDarkMode } = useContext(AppContext)

    const today = dayjs(dayjs().format('YYYY-MM-DD'))
    const start = dayjs(record.start_date, 'YYYY-MM-DD')
    const end = dayjs(record.end_date, 'YYYY-MM-DD')

    const isActive = today.isSameOrAfter(start) && today.isSameOrBefore(end)
    const isUpComing = today.isBefore(start)

    if (isUpComing) {
        return (
            <Tag shape="circle" color="blue">
                <FlexContainer alignItems="center">
                    <img width="14" height="14" src={`https://img.icons8.com/material-outlined/${isDarkMode ? "D4ECFF" : "003d8f"}/future.png`} alt="future" style={{ marginRight: 4 }} />
                    <span className="font-weight-bold">Upcoming</span>
                </FlexContainer>
            </Tag>
        )
    }

    if (isActive) {
        const daysLeft = today.from(end, true)

        return (
            <div>
                <Tag shape="circle" color="green">
                    <FlexContainer alignItems="center">
                        <img width="14" height="14" src={`https://img.icons8.com/material-outlined/48/${isDarkMode ? "d0f0d1" : "1b5924"}/youtube-live.png`} alt="youtube-live" style={{ marginRight: 4 }} />
                        <span className="font-weight-bold">Active</span>
                    </FlexContainer>
                </Tag>
                {/* <div style={{ fontSize: 12 }}>{daysLeft} left</div> */}
            </div>
        )
    }

    return (
        <Tag shape="circle" color="red">
            <FlexContainer alignItems="center">
                <img width="14" height="14" src={`https://img.icons8.com/material-outlined/48/${isDarkMode ? "fee0d5" : "8e0805"}/expired.png`} alt="expired" style={{ marginRight: 4 }} />
                <span className="font-weight-bold">Expired</span>
            </FlexContainer>
        </Tag>
    )
}

const GiftCards = () => {
    const { isMobile, COLOR_MAP } = useContext(AppContext)

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [promotionList, setPromotionList] = useState<IGiftCardPromotion[]>([])

    const [uniqRetailerList, setUniqRetailerList] = useState<string[]>([])
    const [retailerKeyword, setRetailerKeyword] = useState<string>('')

    const [filter, setFilter] = useState({
        showActiveOnly: true,
        showPointOfferOnly: false,
        showDiscountOfferOnly: false,
        searchKeyword: '',
        selectedRetailers: []
    })

    const [selectedPromotion, setSelectedPromotion] = useState<IGiftCardPromotion>()

    const filteredPromotionList = useMemo(() => {
        return promotionList.filter(promotion => {
            if (filter.showActiveOnly) {
                const start = dayjs(promotion.start_date, 'YYYY-MM-DD')
                const end = dayjs(promotion.end_date, 'YYYY-MM-DD')

                if (!dayjs(dayjs().format('YYYY-MM-DD')).isSameOrBefore(end)) {
                    return false
                }
            }

            const offerText = promotion.offer_text.toLowerCase()

            if (filter.showPointOfferOnly && !offerText.includes('point')) {
                return false
            }

            if (filter.showDiscountOfferOnly && !offerText.includes('%')) {
                return false
            }

            if (filter.searchKeyword && !offerText.includes(filter.searchKeyword.toLowerCase())) {
                return false
            }

            if (filter.selectedRetailers?.length) {
                if (filter.selectedRetailers.some(retailer => !promotion.available_retailers.includes(retailer))) {
                    return false
                }
            }

            return true
        })
    }, [promotionList, filter])

    useEffect(() => {
        const pageWrapperEl: any = document.querySelector('.page-wrapper')

        if (pageWrapperEl) {
            pageWrapperEl.style.overflow = 'auto'

            return () => {
                pageWrapperEl.style.overflow = 'hidden'
            }
        }
    }, [])

    useAsyncEffect(async () => {
        setLoading(true)

        const respData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_gift_card_promotion WHERE start_date >= "2024-07-01" AND is_validated = 1 ORDER BY start_date DESC`
                ]
            }
        })

        const uniqRetailerListRespData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT DISTINCT name FROM simpo_octopus.au_gift_card_retailer`
                ]
            }
        })

        console.log(respData)

        setPromotionList(respData.map((item: any) => ({
            ...item,
            available_retailers: item.available_retailers?.split('||')?.filter(Boolean) || []
        })))
        setUniqRetailerList(uniqRetailerListRespData.map((item: any) => item.name))
        setLoading(false)
    }, [])

    const columns = [
        {
            title: 'Gift card offer',
            render: (record: IGiftCardPromotion) => {
                let style: any = {
                    backgroundColor: 'rgba(198,202,205,.5)'
                }

                const backgroundColor = COLOR_MAP[record.platform.toLowerCase()]
                if (backgroundColor) {
                    style.backgroundColor = backgroundColor + 'cc'
                    style.color = 'white'
                }

                return (
                    <FlexContainer flexDirection="column" gap="4px">
                        <div>
                            <Tag style={style} shape="circle">
                                <span className="font-weight-bold">{record.platform}</span>
                            </Tag>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: record.offer_text }} />
                    </FlexContainer>
                )
            }
        },
        {
            title: 'Offer period',
            width: 250,
            render: (record: IGiftCardPromotion) => {
                return <div>Valid from <br /> {dayjs(record.start_date).format('D MMM')} - {dayjs(record.end_date).format('D MMM YYYY')}</div>
            }
        },
        {
            title: 'Status',
            width: 150,
            render: (record: IGiftCardPromotion) => {
                return <GiftCardPromotionStatusRender record={record} />
            }
        },
        {
            title: 'Detail',
            width: 80,
            render: (record: IGiftCardPromotion) => {
                return (
                    <FlexContainer gap="6px">
                        <Button style={{ borderRadius: '50%' }} type="tertiary" theme="solid" icon={<IconSearch />} onClick={() => {
                            navigate(`/gift-cards/${record.gift_card_promotion_auto_increment_id}`)
                        }} />
                        {/* <Button style={{ borderRadius: '50%' }} type="tertiary" icon={<IconHelpCircle />} /> */}
                    </FlexContainer>
                )
            }
        }
    ]

    const handleRow = (index: number) => {
        // 给偶数行设置斑马纹
        if (index % 2 === 0) {
            return {
                style: {
                    background: 'var(--semi-color-fill-0)',
                },
            };
        } else {
            return {};
        }
    };

    return (
        <div className="gift-card-wrapper">
            <div style={{ width: isMobile ? '100%' : '1000px' }}>

                <FlexContainer justifyContent="space-between" alignItems="center" style={{ padding: '12px 0 0 0' }}>
                    <div className="responsive-text" style={{ fontWeight: 700, fontSize: 28, letterSpacing: -1 }}>Gift Card Promotions</div>

                    {!isMobile && <BrandExplorer onGiftCardClick={(v: string) => setSelectedPromotion({ offer_text: v } as any)} />}
                </FlexContainer>

                <FlexContainer className="gift-card-filter-wrapper responsive-background" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="8px">
                    <FlexContainer gap="8px" style={isMobile ? { width: '100%', overflow: 'auto' } : {}}>
                        <div className="checkbox-filter-wrapper responsive-background">
                            <Checkbox checked={filter.showActiveOnly} onChange={e => setFilter(prev => ({ ...prev, showActiveOnly: e.target.checked as boolean }))}>
                                <span className="font-weight-bold">Active Only</span>
                            </Checkbox>
                        </div>

                        <div className="checkbox-filter-wrapper responsive-background">
                            <Checkbox checked={filter.showPointOfferOnly} onChange={e => setFilter(prev => ({ ...prev, showPointOfferOnly: e.target.checked as boolean }))}>
                                <span className="font-weight-bold">Points</span>
                            </Checkbox>
                        </div>

                        <div className="checkbox-filter-wrapper responsive-background">
                            <Checkbox checked={filter.showDiscountOfferOnly} onChange={e => setFilter(prev => ({ ...prev, showDiscountOfferOnly: e.target.checked as boolean }))}>
                                <span className="font-weight-bold">Discount</span>
                            </Checkbox>
                        </div>

                        <TourContainer tourId="participating_retailer_selector" popover={{
                            title: 'Looking for a specific retailer?',
                            description: 'Now you can filter gift card promotions by retailer. For example, find current promotions that can be used at JB Hi-Fi.'
                        }}>
                            <div className="checkbox-filter-wrapper responsive-background" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <Select
                                    size="small"
                                    borderless
                                    showArrow={false}
                                    maxTagCount={1}
                                    prefix={<IconFilter />}
                                    // style={{ marginLeft: 6 }}
                                    placeholder="Filter Participating Retailers"
                                    multiple
                                    optionList={uniqRetailerList.filter(item => !retailerKeyword || item.toLowerCase().includes(retailerKeyword.toLowerCase())).map(retailer => ({
                                        label: renderText(retailer), value: retailer
                                    }))}
                                    onChange={v => setFilter(prev => ({ ...prev, selectedRetailers: v as any }))}
                                    outerTopSlot={(
                                        <div style={{ padding: '4px 12px' }}>
                                            <Input borderless prefix={<IconSearch />} autoFocus placeholder="Search for retailer" style={{ borderRadius: 32 }} onChange={setRetailerKeyword} />
                                        </div>
                                    )}
                                />
                            </div>
                        </TourContainer>
                    </FlexContainer>


                    <Input showClear style={{ width: isMobile ? '100%' : 200, borderRadius: 16 }} prefix={<IconSearch />} onChange={v => setFilter(prev => ({ ...prev, searchKeyword: v }))} placeholder="Search for promotion" />
                </FlexContainer>

                {!isMobile && (
                    <Table
                        columns={columns}
                        sticky={{ top: 48 }}
                        dataSource={filteredPromotionList}
                        loading={loading}
                        // pagination={false}
                        onRow={(__: any, idx: any) => handleRow(idx)}
                    />
                )}

                {isMobile && (
                    <Spin spinning={loading} size="large" style={{ minHeight: 200 }}>
                        <FlexContainer flexDirection="column" gap="8px" style={{ paddingBottom: 12, marginBottom: 56 }}>
                            {filteredPromotionList.map(promotion => {
                                let style: any = {
                                    padding: '4px 24px',
                                    backgroundColor: 'rgba(198,202,205,.7)'
                                }

                                const backgroundColor = COLOR_MAP[promotion.platform.toLowerCase()]
                                if (backgroundColor) {
                                    style.backgroundColor = backgroundColor + 'cc'
                                    style.color = 'white'
                                }

                                return (
                                    <div key={promotion.gift_card_promotion_auto_increment_id} style={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(`/gift-cards/${promotion.gift_card_promotion_auto_increment_id}`)
                                    }}>
                                        <Card
                                            className="mobile-giftcard-wrapper"
                                            bodyStyle={{ padding: 0 }}

                                        // cover={<img src={promotion.pic_url} />}
                                        >
                                            <div style={style}>
                                                <span className="font-weight-bold">{promotion.platform}</span>
                                            </div>

                                            <FlexContainer style={{ padding: '12px 24px', position: 'relative' }} flexDirection="column">

                                                <div className="font-weight-bold" style={{ letterSpacing: -0.2, fontSize: 14 }} dangerouslySetInnerHTML={{ __html: promotion.offer_text }} />
                                                <FlexContainer style={{}} flexDirection="column">
                                                    <span style={{ fontSize: 12, color: 'grey' }}>
                                                        Valid from {dayjs(promotion.start_date).format('DD/MM/YYYY')} - {dayjs(promotion.end_date).format('DD/MM/YYYY')}
                                                    </span>
                                                    <div>
                                                        {columns.find(item => item.title === 'Status')?.render?.(promotion)}
                                                    </div>
                                                </FlexContainer>
                                            </FlexContainer>
                                        </Card>
                                    </div>
                                )
                            })}

                            {!loading && filteredPromotionList.length === 0 && (
                                <FlexContainer style={{ width: '100%', padding: '32px 0 16px', color: 'grey' }} justifyContent="center">
                                    <div className="font-weight-bold">Oops! No records found.</div>
                                </FlexContainer>
                            )}
                        </FlexContainer>
                    </Spin>
                )}
            </div>

            {isMobile ? (
                <SideSheet
                    className="gift-card-promotion-detail-modal-wrapper"
                    visible={!!selectedPromotion}
                    footer={null}
                    onCancel={() => setSelectedPromotion(undefined)}
                    placement="bottom"
                    height="70vh"
                    style={{ borderRadius: '12px 12px 0 0' }}
                    closable={false}
                    headerStyle={{ padding: 0 }}
                    bodyStyle={{ padding: 0 }}
                // title={(
                //     <span className="font-weight-bold">Gift card promotion detail</span>
                // )}
                >
                    <GiftCardPromotionDetailBody giftCardPromotion={selectedPromotion} />
                </SideSheet>
            ) : (
                <Modal
                    className="gift-card-promotion-detail-modal-wrapper"
                    visible={!!selectedPromotion}
                    footer={null}
                    onCancel={() => setSelectedPromotion(undefined)}
                    width="760px"
                    title={(
                        <span className="font-weight-bold" >Gift card promotion detail</span>
                    )}
                >
                    <GiftCardPromotionDetailBody giftCardPromotion={selectedPromotion} />
                </Modal>
            )}

            {isMobile && <BrandExplorer onGiftCardClick={(v: string) => setSelectedPromotion({ offer_text: v } as any)} />}
        </div>
    )
}

export default GiftCards