import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { IGiftCardDetail } from "../../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import FlexContainer from "../../../components/FlexContainer"
import { Button, Divider, Form, SideSheet, useFormApi } from "@douyinfe/semi-ui"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import { omit } from "lodash-es"
import { AppContext } from "../../../App"

interface IGiftCardImageRenderProps {
    pic_url: string;
    margin_offset?: string;
    height: number;
    debug?: boolean
}

export const GiftCardRender = ({ pic_url, margin_offset, height, debug }: IGiftCardImageRenderProps) => {

    // const imgHeight = 
    const [imgWidth, setImgWidth] = useState(0)
    const [imgHeight, setImgHeight] = useState(0)

    const divRef = useRef<any>()

    const arr = (margin_offset || '').split(' ').filter(Boolean).map((v) => parseFloat(v))
    const style = {
        marginTop: arr[0] || 0,
        marginRight: arr[1] || 0,
        marginBottom: arr[2] || 0,
        marginLeft: arr[3] || 0,
        borderRadius: 4,
    }

    const heightOffset = height / (80 + style.marginTop + style.marginBottom) // checked
    // const widthOffset = 


    const scale = useMemo(() => {
        return (80 - style.marginTop - style.marginBottom) / imgHeight
    }, [imgHeight, height])


    return (
        <FlexContainer justifyContent="center" alignItems="center" style={{ height, width: height * 1.6 }}>
            {!margin_offset ? (
                <img src={pic_url} height={height} style={{ borderRadius: 4 }} />
            ) : (
                <div style={{ height, overflow: 'hidden', ...debug ? { background: debug ? 'white' : 'transparent' } : { width: height * 1.6 } }}>
                    <div ref={divRef as any} style={{ transform: `scale(${heightOffset})`, transformOrigin: '0 0', overflow: 'visible' }}>
                        <img src={pic_url} height="80px" style={style} onLoad={(e: any) => {
                            setImgWidth(e.target.naturalWidth)
                            setImgHeight(e.target.naturalHeight)
                        }} />
                    </div>
                </div>
            )}
        </FlexContainer >
    )
}

const GiftCardManagement = () => {
    const { navbarHeight, windowHeight } = useContext(AppContext)

    const [giftCardList, setGiftCardList] = useState<IGiftCardDetail[]>([])
    const [selectedGiftCard, setSelectedGiftCard] = useState<IGiftCardDetail>()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState<Record<string, any>>({})

    const formRef = useRef<FormApi>()

    useAsyncEffect(async () => {
        const respData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_gift_card`
                ]
            }
        })

        setGiftCardList(respData)
    }, [])

    useEffect(() => {
        if (selectedGiftCard) {
            formRef.current?.setValues(selectedGiftCard)
            setFormData(selectedGiftCard)
        }
    }, [selectedGiftCard])

    const handleSave = useCallback(async () => {
        setLoading(true)

        await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.insert',
                requestParams: [
                    `simpo_octopus`, 'au_gift_card', omit(formData, ['created_at', 'updated_at']), {
                        onDuplicateKeyUpdate: {
                            'gift_card_id': 'VALUES(gift_card_id)',
                            'name': 'VALUES(name)',
                            'pic_url': 'VALUES(pic_url)',
                            'detail_url': 'VALUES(detail_url)',
                            'mutually_exclusive_terms': 'VALUES(mutually_exclusive_terms)',
                            'margin_offset': 'VALUES(margin_offset)',
                            'remark': 'VALUES(remark)',
                            'activation_fee': 'VALUES(activation_fee)'
                        }
                    }
                ]
            }
        })

        setGiftCardList(arr => {
            return arr.map(item => {
                if (item.gift_card_auto_increment_id === formData.gift_card_auto_increment_id) {
                    return formData as any
                } else {
                    return item
                }
            })
        })
        setLoading(false)
    }, [formData])

    return (
        <FlexContainer style={{ padding: '0 16px' }}>
            <div>
                <div className="font-weight-black responsive-text" style={{ padding: '8px 0', fontSize: 20, letterSpacing: -0.5 }}>
                    Gift Card Management
                </div>


                <FlexContainer gap="8px" flexWrap="wrap" style={{ flexGrow: 1, paddingBottom: 16, height: windowHeight - navbarHeight - 46, overflow: 'auto', boxSizing: 'border-box' }}>
                    {giftCardList?.map(gc => {
                        return (
                            <div key={gc.gift_card_id} style={{ cursor: 'pointer' }} onClick={() => setSelectedGiftCard(gc)}>
                                <GiftCardRender pic_url={gc.pic_url} margin_offset={gc.margin_offset} height={100} />
                            </div>

                        )
                    })}
                </FlexContainer>
            </div>

            <div style={{ width: 448, flexShrink: 0 }}></div>

            <SideSheet visible={!!selectedGiftCard} onCancel={() => setSelectedGiftCard(undefined)} title="Edit gift card meta data" mask={false}>
                <Form getFormApi={formApi => formRef.current = formApi} onValueChange={v => setFormData(prev => ({ ...prev, ...v }))}>
                    <Form.Input label="Gift card ID" field="gift_card_id" />
                    <Form.Input label="Name" field="name" />
                    <Form.Input label="Logo" field="pic_url" />
                    <Form.Input label="Detail URL" field="detail_url" />
                    <Form.Input label="Activation fee" field="activation_fee" />
                    <Form.Input label="Mutually exclusive terms" field="mutually_exclusive_terms" />
                    <Form.Input label="Margin offset" field="margin_offset" />
                    <Form.TextArea label="Remark" field="remark" rows={10} />
                </Form>

                <Button type="primary" theme="solid" style={{ marginTop: 12 }} loading={loading} onClick={handleSave}>Save</Button>

                <Divider style={{ margin: '24px 0 12px' }} />


                {selectedGiftCard && (
                    <div style={{ marginBottom: 24 }}>
                        <div className="font-weight-bold responsive-text">Preview</div>
                        <GiftCardRender pic_url={selectedGiftCard.pic_url} margin_offset={formData.margin_offset} height={80} debug />
                    </div>

                )}
            </SideSheet>
        </FlexContainer>
    )
}

export default GiftCardManagement