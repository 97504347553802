import { act, useCallback, useContext, useMemo, useState } from "react"
import { ICatalogue, ICatalogueItem } from "../../../typings"
import { useAsyncEffect } from "ahooks"
import FlexContainer from "../../../components/FlexContainer"
import { request } from "../../../utils"
import { Button, Descriptions, MarkdownRender, RadioGroup, SideSheet, Table, Tag, Toast, Tooltip } from "@douyinfe/semi-ui"
import { capitalize, groupBy, pick } from "lodash-es"
import { AppContext } from "../../../App"
import { IconArrowLeft, IconArrowRight, IconSetting } from "@douyinfe/semi-icons"
import GPTHelper from "../../../components/GPTHelper"
import copy from "copy-to-clipboard"

function jsonToMarkdownTable(jsonArray: any[]) {
    if (!Array.isArray(jsonArray) || jsonArray.length === 0) {
        return '';
    }

    // Get table headers from the keys of the first object
    const headers = Object.keys(jsonArray[0]);

    // Create the header row
    let markdownTable = `| ${headers.join(' | ')} |\n`;

    // Create the separator row
    markdownTable += `| ${headers.map(() => '---').join(' | ')} |\n`;

    // Create the data rows
    jsonArray.forEach(obj => {
        const row = headers.map(header => obj[header] || '').join(' | ');
        markdownTable += `| ${row} |\n`;
    });

    return markdownTable;
}

const CatalogueManagement = () => {
    const { windowHeight, windowWidth, navbarHeight } = useContext(AppContext)

    const [loading, setLoading] = useState(false)

    const [catalogueList, setCatalogueList] = useState<ICatalogue[]>([])
    const [itemList, setItemList] = useState<ICatalogueItem[]>([])

    const [activeTab, setActiveTab] = useState('')
    const [selectedItem, setSelectedItem] = useState<ICatalogueItem>()

    const [exportMdSidesheetVisible, setExportMdSidesheetVisible] = useState(false)

    useAsyncEffect(async () => {
        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_salefinder_catalogue WHERE end_date >= CURDATE()`
                ]
            }
        })

        setCatalogueList(respData)
    }, [])

    useAsyncEffect(async () => {
        setItemList([])

        if (!activeTab) {
            return
        }

        setLoading(true)

        const respData = await request({
            // baseURL: 'http://localhost:7001',
            data: {
                service: 'au.hotaudeals.getCatalogueItemList',
                requestParams: [
                    activeTab, 1, 500, [], 'ORDER BY -discount_rate DESC'
                ]
            }
        })

        setItemList(respData.productList)
        setLoading(false)
    }, [activeTab])

    const md = useMemo(() => {
        const itemListGroupByCategory = groupBy(itemList, el => el?.category?.split('||')[0])

        let output: string = ''

        Object.entries(itemListGroupByCategory).filter(([category]) => !!category).forEach(([category, arr]) => {
            output += [
                '',
                `### ${category}`,
                `- ${arr.length} items on sale`,
                `- ${arr.filter(item => item.lowest_price && item.lowest_price >= item.new_price).length} at their lowest price since July`,
                '', ''
            ].join('\n')

            output += jsonToMarkdownTable(arr.map((item, idx) => {
                return {
                    "#": `${idx+1}.`,
                    Item: `${ item.name }`,
                    Was: item.origin_price ? `~~$${ item.origin_price.toFixed(2) }~~` : '',
                    Discount: item.discount_rate ? `${ item.discount_rate.toFixed(0) }% ` : '',
                    Now: `$${ item.new_price.toFixed(2) }`,
                    Lowest: item.lowest_price 
                        ? item.lowest_price >= item.new_price
                            ? '✅'
                            : `❌ - $${ item.lowest_price.toFixed(2) }`
                        : 'N/A',
                }
            }))
        })
        
        return output
    }, [itemList])

    return (
        <FlexContainer>
            <FlexContainer flexDirection="column" style={{ flexGrow: 1 }}>
                <div style={{ padding: '8px 0 8px 16px' }}>
                    <div className="font-weight-black responsive-text" style={{ fontSize: 20, letterSpacing: -0.5 }}>Catalogue Management</div>

                    <FlexContainer justifyContent="space-between" style={{ marginTop: 4 }}>
                        <RadioGroup
                            type="button"
                            onChange={e => setActiveTab(e.target.value)}
                            options={catalogueList.map(catalogue => {
                                return {
                                    key: catalogue.catalogue_id,
                                    label: capitalize(catalogue.platform),
                                    value: catalogue.platform
                                }
                            })}
                        />

                        <div>
                            <Button onClick={() => [
                                setExportMdSidesheetVisible(true)
                            ]}>Export as Markdown</Button>
                        </div>
                    </FlexContainer>


                </div>

                <Table
                    dataSource={itemList}
                    style={{ height: windowHeight - navbarHeight - 82, overflow: 'auto' }}
                    sticky
                    loading={loading}
                    columns={[
                        {
                            title: 'Name',
                            render: (record: ICatalogueItem) => {
                                const skuIdCount = record.sku_id_list?.split(',').length || 0

                                return (
                                    <div>
                                        <Tooltip content={record.sku_id_list}>
                                            <Tag style={{ cursor: 'pointer' }}>{skuIdCount} SKUs</Tag>
                                        </Tooltip>
                                        <div style={{ marginTop: 4 }}>
                                            <div>{record.name}</div>
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Offer text',
                            width: 200,
                            dataIndex: 'offer_text'
                        },
                        {
                            title: 'Price',
                            render: (record: ICatalogueItem) => {
                                return (
                                    <FlexContainer alignItems="center" gap="4px">
                                        {!!record.origin_price && (
                                            <>
                                                {record.origin_price}
                                                <IconArrowRight style={{ fontSize: 12 }} />
                                            </>
                                        )}
                                        {record.new_price} ({Math.abs(record.discount_rate || 0)}% off)
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: 'Tags',
                            render: (record: ICatalogueItem) => {
                                return (
                                    <FlexContainer gap="4px">
                                        {!!record.lowest_price && record.lowest_price >= record.new_price && <Tag color="red">Historical low</Tag>}

                                        {!!record.lowest_price && record.lowest_price < record.new_price && <Tag>Lowest: ${record.lowest_price}</Tag>}
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: 'Action',
                            width: 80,
                            render: (record: ICatalogueItem) => {
                                return (
                                    <Button theme="solid" type="tertiary" icon={<IconSetting />} style={{ borderRadius: 32 }} onClick={() => {
                                        setSelectedItem(record)
                                    }} />
                                )
                            }
                        }
                    ]}
                    pagination={false}
                />
            </FlexContainer>

            <div style={{ width: 448, flexShrink: 0 }} />

            <SideSheet visible={!!selectedItem} title="Post catalogue item" onCancel={() => setSelectedItem(undefined)} mask={false}>
                <GPTHelper system_prompt="" user_content="" />
            </SideSheet>

            <SideSheet 
                visible={exportMdSidesheetVisible} 
                title="Export as Markdown" 
                onCancel={() => setExportMdSidesheetVisible(false)} 
                mask={false}
                footer={(
                    <FlexContainer justifyContent="flex-end">
                        <Button theme="solid" onClick={() => {
                            copy(md)
                            Toast.success('Copied to clipboard')
                        }}>Copy Markdown</Button>
                    </FlexContainer>
                )}
            > 
                <Descriptions
                    data={[
                        { key: 'Title', value: `½ Price: ${itemList.length} items on sale in this week's catalogue, ${itemList.filter(item => item.lowest_price && item.lowest_price >= item.new_price).length} at their lowest since July @ ${capitalize(activeTab)}` },
                        // { key: 'Start date', value: `${catalogueList.find(item => item.)}` }
                        
                    ]}
                />
            </SideSheet>
        </FlexContainer>
    )
}

export default CatalogueManagement  