import { MarkdownRender } from "@douyinfe/semi-ui"
import { md } from "./example"
import { useContext } from "react"
import { AppContext } from "../../App"
import FlexContainer from "../../components/FlexContainer"


const Article = () => {
    const { windowHeight, navbarHeight } = useContext(AppContext)

    return (
        <FlexContainer justifyContent="center" style={{ padding: 24, height: windowHeight - navbarHeight, boxSizing: 'border-box', overflow: 'auto' }}>
            <div className="responsive-background-secondary" style={{ padding: 24, borderRadius: 12, maxWidth: 1000, height: 'fit-content' }}>
                <MarkdownRender raw={md.replace(/\*\*\*\*/g, '** **')} />
            </div>
        </FlexContainer>


    )
}

export default Article