import { Divider, Tag } from "@douyinfe/semi-ui"
import FlexContainer from "../../../../components/FlexContainer"
import { IGiftCardDetail, IGiftCardPromotion } from "../../../../typings"
import { renderText } from "../../../../utils"
import { useContext, useMemo } from "react"
import { AppContext } from "../../../../App"
import Marquee from "react-fast-marquee";
import dayjs from "dayjs"
import { uniqueId } from "lodash-es"
import { GiftCardRender } from "../../../ManagementPage/GiftCardManagement"

interface IGiftCardPosterProps {
    giftCardPromotion: IGiftCardPromotion;
    giftCardList: IGiftCardDetail[];
    embedded?: boolean;
}

const parseOfferText = (text: string) => {
    if (!text) {
        return {}
    }

    const whenIndex = text.toLowerCase().indexOf(' when ')
    const onIndex = text.toLowerCase().indexOf(' on ')
    const cottonOnIndex = text.toLowerCase().indexOf('cotton on')

    if (whenIndex >= 0) {
        const arr = [text.slice(0, whenIndex), text.slice(onIndex + 5)].map(item => item.trim())

        return {
            title: arr[0],
            content: arr[1]
        }
    }

    if (onIndex >= 0 && (cottonOnIndex === -1 || Math.abs(onIndex - cottonOnIndex) > 8)) {
        const arr = [text.slice(0, onIndex), text.slice(onIndex + 4)].map(item => item.trim())

        return {
            title: arr[0],
            content: arr[1]
        }
    }

    const offIndex = text.toLowerCase().indexOf(' off ')
    if (offIndex >= 0) {
        const arr = [text.slice(0, offIndex + 4), text.slice(offIndex + 5)].map(item => item.trim())

        return {
            title: arr[0],
            content: arr[1]
        }
    }

    return {}
}

const GiftCardPoster = ({ giftCardPromotion, giftCardList, embedded }: IGiftCardPosterProps) => {
    const { COLOR_MAP, isMobile, windowWidth } = useContext(AppContext)

    const needMarquee = useMemo(() => {
        if (embedded) {
            return false
        }

        const itemWidth = 90 * 1.2
        const parentWidth = isMobile ? windowWidth : 700

        return giftCardList.length > parentWidth / itemWidth

    }, [giftCardList, isMobile, windowWidth, embedded])

    if (!giftCardPromotion) {
        return <></>
    }

    const { title, content } = parseOfferText(giftCardPromotion.offer_text)
    const lowerCasedPlatform = giftCardPromotion.platform.toLowerCase()

    return (
        <FlexContainer justifyContent="center" alignItems="center" flexDirection="column" gap="8px" style={{ width: '100%', padding: '24px 0 48px', color: 'black' }}>
            {isMobile ? (
                <>
                    <div className="font-weight-bold responsive-text" style={{ fontSize: 36, letterSpacing: -2, lineHeight: '50px' }}>{renderText(title)}</div>
                    <div className="font-weight-bold responsive-text" style={{ fontSize: 15, textAlign: 'center', lineHeight: '21px', width: '70%', marginTop: 0 }}>{renderText(content)}</div>
                </>
            ) : (
                <>
                    <div className="font-weight-bold responsive-text" style={{ fontSize: embedded ? 56 : 72, letterSpacing: -3, lineHeight: embedded ? '78px' : '100px' }}>{renderText(title)}</div>
                    <div className="font-weight-bold responsive-text" style={{ fontSize: embedded ? 18 : 24, letterSpacing: -.8, textAlign: 'center', lineHeight: embedded ? '25px' : '33px', width: embedded ? '80%' :'65%', marginTop: 0 }}>{renderText(content)}</div>
                </>
            )}


            {!!giftCardList.length && (
                <>
                    {needMarquee ? (
                        <Marquee>
                            <FlexContainer gap="8px" style={{ marginTop: 8, marginRight: 8 }}>
                                {giftCardList.map(gc => {
                                    return (
                                        <div key={gc.gift_card_id}>
                                            <GiftCardRender height={60} pic_url={gc.pic_url} margin_offset={gc.margin_offset} />
                                        </div>
                                    )
                                })}
                            </FlexContainer>
                        </Marquee>
                    ) : (
                        <FlexContainer gap="6px" flexWrap="wrap" justifyContent="center" style={{ marginTop: 8, marginRight: 8 }}>
                            {giftCardList.map(gc => {
                                return (
                                    <div key={gc.gift_card_id}>
                                        <img src={embedded ? `https://www.hotaudeals.com/api/v1/redirect/img/${uniqueId()}?imgUrl=${gc.pic_url}` : gc.pic_url} height={embedded ? '50px' : "60px"} style={{ borderRadius: 4 }} />
                                    </div>
                                )
                            })}
                        </FlexContainer>
                    )}
                </>

            )}

            {!giftCardList.length && !!giftCardPromotion.pic_url && giftCardPromotion.pic_url.endsWith('.jpg') && (
                <FlexContainer justifyContent="center">
                    <img src={giftCardPromotion.pic_url} style={{ borderRadius: 4, width: isMobile ? "calc(100vw - 48px)" : "calc(100% - 96px)" }} />
                </FlexContainer>
            )}

            <FlexContainer style={{ marginTop: 12 }} gap="8px" flexDirection={embedded || isMobile ? 'column' : 'row'} alignItems="center">
                {lowerCasedPlatform === 'coles' && (
                    <div>
                        <Tag size="large" style={{ background: COLOR_MAP.flybuys, fontSize: 16, color: 'white' }}>
                            <div>Flybuys</div>
                        </Tag>
                    </div>

                )}

                {lowerCasedPlatform === 'woolworths' && (
                    <div>
                        <Tag size="large" style={{ background: COLOR_MAP['everyday-rewards'], fontSize: 16, color: 'white' }}>
                            <div>
                                Everyday Rewards
                            </div>
                        </Tag>
                    </div>
                )}

                <div>
                    <Tag size="large" style={{ background: COLOR_MAP[lowerCasedPlatform] || 'rgb(0,100,250)', fontSize: 16, color: 'white' }}>
                        <div>
                            Exclusive at {giftCardPromotion.platform}
                        </div>
                    </Tag>
                </div>
            </FlexContainer>

            <FlexContainer justifyContent="center">
                <div className="font-weight-bold" style={{ color: 'grey', letterSpacing: -0.5 }}>
                    Valid from {dayjs(giftCardPromotion.start_date).format('DD/MM')} to {dayjs(giftCardPromotion.end_date).format('DD/MM/YYYY')}
                </div>
            </FlexContainer>
        </FlexContainer>
    )
}

const Wrapper = ({ giftCardPromotion, giftCardList, embedded }: IGiftCardPosterProps) => {
    const offerTextArr = giftCardPromotion?.offer_text?.split('\n').filter(Boolean) || []

    if (offerTextArr.length === 1) {
        return <GiftCardPoster giftCardPromotion={giftCardPromotion} giftCardList={giftCardList} embedded />
    }

    return (
        <div>
            {offerTextArr.map((offer_text, rowIdx) => {
                return (
                    <>
                        {rowIdx !== 0 && <Divider />}
                        <GiftCardPoster giftCardPromotion={Object.assign({}, giftCardPromotion, { offer_text })} giftCardList={giftCardList.filter(item => item.row_index === rowIdx)} embedded />
                    </>
                )
            })}
        </div>
    )
}

export default Wrapper