import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Popup, ScaleControl, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { IAirport, IRewardSeatSummary } from '../../../../typings';
import { RewardFlightContext } from '../..';
import './index.less'
import { AppContext } from '../../../../App';
import * as turf from '@turf/turf'; // Import Turf.js for geospatial calculations
import { Button, Notification, SideSheet, Tag, Toast, Tooltip } from '@douyinfe/semi-ui';
import FlexContainer from '../../../../components/FlexContainer';
import { IconArrowRight, IconChevronRight, IconClose, IconCoinMoneyStroked, IconExternalOpen } from '@douyinfe/semi-icons';
import AirportNameRender from '../AirportNameRender';
import { calculateSteps, GeoJSONPoint } from '../../../RewardFlightsDetail/components/FlightRouteRender';
import { formatNumber } from '../../../../utils';
import { DescriptionItem } from '../../../RewardFlightsDetail';
import { calcHaversineDistance, estimateFlightDuration, estimateTimezoneDifference } from '../../../RewardFlightsDetail/utils';

export const MAPBOX_TOKEN = 'pk.eyJ1IjoienM1MjAiLCJhIjoiY2xsd2J4a2s5MHNxcjNqb2JuODl2YWwzciJ9.tJUFy6j43vQyKm8QpiDPlw';

export const VIEW_STATE_DICT = {
    'Anywhere': {
        "longitude": 133.37703424621412,
        "latitude": -26.26276741950391,
        "zoom": 3.6294619164445927,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    'Australia': {
        "longitude": 133.37703424621412,
        "latitude": -26.26276741950391,
        "zoom": 3.6294619164445927,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    'UK & Europe': {
        "longitude": 10.505601881758736,
        "latitude": 48.86913480020897,
        "zoom": 3.4228287349514424,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    'North America': {
        "longitude": -112.96065632334941,
        "latitude": 40.591222008804436,
        "zoom": 2.8698919250975354,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    "Asia Pacific": {
        "longitude": 97.49571471636511,
        "latitude": 23.757988374146464,
        "zoom": 2.858673656188374,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    "Middle East": {
        "longitude": 44.37652821474242,
        "latitude": 30.14921038756067,
        "zoom": 3.4316898390551445,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    }
}

// Helper function to adjust for the International Date Line crossing
export const adjustForIDL = (coords: [number, number][]) => {
    const adjustedCoords: [number, number][] = [];
    let prevLon = coords[0][0];

    for (const [lon, lat] of coords) {
        // Check if crossing the IDL
        if (Math.abs(lon - prevLon) > 180) {
            // Adjust for crossing IDL
            if (lon > prevLon) {
                adjustedCoords.push([lon - 360, lat]);
            } else {
                adjustedCoords.push([lon + 360, lat]);
            }
        } else {
            adjustedCoords.push([lon, lat]);
        }
        prevLon = lon;
    }
    return adjustedCoords;
};

interface IAirportMapProps {
    departureAirport: string;
    routeList: {
        routeKey: string;
        rewardSeatList: IRewardSeatSummary[];
    }[];
    viewState: any;
}

const AirportMap = ({ departureAirport, routeList, viewState }: IAirportMapProps) => {

    const { windowHeight, isDarkMode, isMobile } = useContext(AppContext)
    const { airportDict, showRouteDetail } = useContext(RewardFlightContext)

    const [hoveredDestiantionAirportInfo, setHoveredDestiantionAirportInfo] = useState<IAirport>()
    const [selectedDestiantionAirportInfo, setSelectedDestiantionAirportInfo] = useState<IAirport>()

    const [pointData, setPointData] = useState<GeoJSONPoint>();

    const isAnimatingRef = useRef(false)
    const isNotifiedRef = useRef(false)

    let counter = 0
    let steps = 500

    const createRouteData = (airport1: IAirport, airport2: IAirport, overrideSteps = false) => {
        const startCoords: [number, number] = [parseFloat(airport1.longitude), parseFloat(airport1.latitude)];
        const endCoords: [number, number] = [parseFloat(airport2.longitude), parseFloat(airport2.latitude)];

        const lineDistance = turf.length({
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [startCoords, endCoords],
            },
        } as any);

        steps = calculateSteps(lineDistance);


        // Generate points along the great-circle path
        const arc = turf.greatCircle(startCoords, endCoords, { npoints: steps });

        const route: any = {
            type: 'FeatureCollection',
            features: arc.geometry.coordinates.length === 2 ? [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: arc.geometry.coordinates[0],
                    },
                },
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: arc.geometry.coordinates[1],
                    },
                },
            ] : [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: arc.geometry.coordinates,
                    },
                },
            ],
        };

        return route;
    }

    const selectedRoute = useMemo(() => {
        if (!selectedDestiantionAirportInfo) {
            return undefined
        }

        return routeList.find(route => route.routeKey.endsWith(`_${departureAirport}_${selectedDestiantionAirportInfo.airport_code}`))
    }, [departureAirport, routeList, selectedDestiantionAirportInfo])

    const hideRouteConnection = useMemo(() => {
        if (routeList.length > 100) {
            if (!isNotifiedRef.current) {
                Notification.info({
                    title: <span className="font-weight-bold">Reminder</span>,
                    duration: 5,
                    content: <span className="font-weight-bold">Route connection is hidden to optimise web performance</span>,
                    position: isMobile ? 'bottom' : 'bottomRight'
                })
                isNotifiedRef.current = true
            }

            return true
        }

        return false
    }, [routeList])

    // Function to animate the point
    const animatePoint = useCallback(() => {
        if (!pointData || !selectedDestiantionAirportInfo) return;
        const routeData = createRouteData(airportDict[departureAirport], selectedDestiantionAirportInfo, true)

        let counterOffset = 0
        let coordinatesIdx = 0

        if (counter >= routeData.features[0].geometry.coordinates.length) {
            coordinatesIdx = 1
            counterOffset = routeData.features[0].geometry.coordinates.length
        }

        const start = routeData.features[coordinatesIdx].geometry.coordinates[counter - counterOffset];
        const end = routeData.features[coordinatesIdx].geometry.coordinates[counter + 1 - counterOffset] || start;

        const bearing = turf.bearing(turf.point(start), turf.point(end));

        // Update point with new coordinates and bearing
        const newPointData = {
            ...pointData,
            features: [
                {
                    ...pointData.features[0],
                    geometry: {
                        ...pointData.features[0].geometry,
                        coordinates: start,
                    },
                    properties: {
                        bearing,
                    },
                },
            ],
        };

        setPointData(newPointData as any);

        if (counter + 1 >= steps) {
            counter = 0
        } else {
            counter += 1
        }

        requestAnimationFrame(animatePoint);
    }, [pointData, selectedDestiantionAirportInfo])


    useEffect(() => {
        isAnimatingRef.current = false
        if (selectedDestiantionAirportInfo) {
            setPointData({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [parseFloat(selectedDestiantionAirportInfo.longitude), parseFloat(selectedDestiantionAirportInfo.latitude)],
                        },
                        properties: {},
                    },
                ],
            });
        }
    }, [selectedDestiantionAirportInfo])

    useEffect(() => {
        if (pointData && !isAnimatingRef.current) {
            animatePoint()
            isAnimatingRef.current = true
        }
    }, [pointData])

    const rewardFlightDetail = (
        <>
            {selectedDestiantionAirportInfo && selectedRoute && (
                <FlexContainer flexDirection='column' gap="8px" className="reward-flight-info-card-wrapper responsive-background-secondary responsive-text">
                    <FlexContainer justifyContent='space-between' alignItems='center'>
                        <FlexContainer alignItems='center' style={{ fontWeight: 700, fontSize: 16 }} gap="4px">
                            <img width="20" height="20" src={`https://img.icons8.com/ios-filled/50/${isDarkMode ? "FFFFFF" : "000000"}/airplane-take-off.png`} alt="airplane-take-off" />
                            Reward Flights
                        </FlexContainer>
                        <Button size="small" type="tertiary" icon={<IconClose />} theme="borderless" onClick={() => setSelectedDestiantionAirportInfo(undefined)} />
                    </FlexContainer>

                    <FlexContainer alignItems='center' gap="8px" style={{ fontWeight: 700, fontSize: 16, whiteSpace: 'nowrap' }}>
                        <AirportNameRender airport={airportDict[departureAirport]} hideAirportCode />

                        <IconArrowRight style={{ fontSize: 14 }} />

                        <AirportNameRender airport={selectedDestiantionAirportInfo} hideAirportCode />
                    </FlexContainer>

                    <FlexContainer flexDirection='column' gap="8px">

                        <DescriptionItem label="Distance*" value={calcHaversineDistance(airportDict[departureAirport], selectedDestiantionAirportInfo)} />
                        <DescriptionItem label="Duration*" value={estimateFlightDuration(airportDict[departureAirport], selectedDestiantionAirportInfo)} />
                        <DescriptionItem
                            label="Timezone difference*"
                            value={(
                                <FlexContainer alignItems="center" gap="6px">
                                    <span>{estimateTimezoneDifference(airportDict[departureAirport], selectedDestiantionAirportInfo)}</span>
                                    <IconExternalOpen style={{ cursor: 'pointer' }} onClick={() => {
                                        window.open(`https://www.google.com/search?btnI=1&q=timezone difference between ${airportDict[departureAirport]?.city_name} and ${selectedDestiantionAirportInfo?.city_name}`)
                                    }} />
                                </FlexContainer>
                            )}
                        />

                        <div style={{ width: '100%' }}>
                            <Button theme="solid" block style={{ height: 40 }} onClick={() => {
                                window.open(`/reward-flights/${departureAirport}/${selectedDestiantionAirportInfo.airport_code}`)
                            }}>
                                Starting from {formatNumber(Math.min(...selectedRoute.rewardSeatList.map(item => item.min_fare_pts_price)))} PTS
                            </Button>
                        </div>
                    </FlexContainer>
                </FlexContainer>
            )}
        </>
    )

    return (
        <div className="airport-map-wrapper" style={{ height: `calc(${windowHeight}px - 52px)`, position: 'relative', width: '100%' }}>
            <Map
                reuseMaps
                initialViewState={viewState || {
                    latitude: 40,
                    longitude: -100,
                    zoom: 1,
                    bearing: 0,
                    pitch: 0
                }}
                mapStyle={isDarkMode ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v11"}
                mapboxAccessToken={MAPBOX_TOKEN}
            >
                <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <ScaleControl />

                {!!airportDict[departureAirport] && (
                    <Marker
                        longitude={parseFloat(airportDict[departureAirport].longitude)}
                        latitude={parseFloat(airportDict[departureAirport].latitude)}
                    >
                        {/* paint={{
                            'circle-radius': 6,
                            'circle-color': 'rgb(250,102,76)', // Red color for the airport dots
                            'circle-stroke-width': 2,
                            'circle-stroke-color': '#FFFFFF', // White border around the dots
                        }} */}

                        <div style={{
                            position: 'relative',
                            cursor: 'pointer'
                        }}>
                            <Tooltip
                                position="right"
                                content={(
                                    <div className="font-weight-bold">{airportDict[departureAirport]?.city_name} ({airportDict[departureAirport]?.airport_code})</div>
                                )}
                            >
                                <div
                                    style={{
                                        background: isDarkMode ? 'rgb(84,169,255)' : 'rgb(0,100,250)',
                                        border: '3px solid #FFFFFF',
                                        borderRadius: 32,
                                        width: 12,
                                        height: 12,
                                        zIndex: 2,
                                        position: 'relative'
                                    }}
                                />
                            </Tooltip>

                            <div className="dot" />
                        </div>
                    </Marker>
                )}

                {routeList.map((route, idx) => {
                    const airportCode = route.routeKey.split('_')[2]
                    const destinationAirportInfo = airportDict[airportCode]

                    const isHovered = destinationAirportInfo.airport_code === hoveredDestiantionAirportInfo?.airport_code
                    const isSelected = destinationAirportInfo.airport_code === selectedDestiantionAirportInfo?.airport_code

                    return (
                        <>
                            {(!hideRouteConnection || isSelected) && (
                                <Source key={route.routeKey} type="geojson" data={createRouteData(airportDict[departureAirport], destinationAirportInfo)}>
                                    <Layer
                                        type="line"
                                        paint={{
                                            'line-width': 1,
                                            'line-color': '#d995ac',

                                            ...isHovered ? {
                                                'line-width': 3,
                                                'line-color': 'red',
                                            } : {},

                                            ...isSelected ? {
                                                'line-width': 5,
                                                'line-color': isDarkMode ? 'rgb(84,169,255)' : 'rgb(0,100,250)',
                                            } : {}
                                        }}
                                    />
                                </Source>
                            )}

                            <Marker
                                longitude={parseFloat(destinationAirportInfo.longitude)}
                                latitude={parseFloat(destinationAirportInfo.latitude)}
                            // anchor="top"
                            >
                                <Tooltip
                                    position="right"
                                    content={(
                                        <div className="font-weight-bold">{destinationAirportInfo?.city_name} ({destinationAirportInfo?.airport_code})</div>
                                    )}
                                >
                                    <div
                                        className="destination-airport-node-wrapper"
                                        style={{
                                            background: 'rgb(250,102,76)',
                                            border: '2px solid #FFFFFF',
                                            borderRadius: 32,
                                            width: 12,
                                            height: 12,

                                            ...isSelected || isHovered ? {
                                                transform: 'scale(1.4)'
                                            } : {}
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setSelectedDestiantionAirportInfo(destinationAirportInfo)
                                        }}
                                        onMouseEnter={(e) => {
                                            e.stopPropagation()
                                            setHoveredDestiantionAirportInfo(destinationAirportInfo)
                                        }}
                                        onMouseLeave={(e) => {
                                            e.stopPropagation()
                                            setHoveredDestiantionAirportInfo(undefined)
                                        }}
                                    />
                                </Tooltip>
                            </Marker>
                        </>

                    )
                })}

                {!!selectedDestiantionAirportInfo && pointData && (
                    <Source id="point" type="geojson" data={pointData}>
                        <Layer
                            id="point"
                            type="symbol"
                            layout={{
                                'icon-image': 'airport', // Use an icon available in Mapbox or add a custom one
                                'icon-size': 1.5,
                                'icon-rotate': ['get', 'bearing'],
                                'icon-rotation-alignment': 'map',
                                'icon-allow-overlap': true,
                                'icon-ignore-placement': true,
                            }}
                        />
                    </Source>
                )}
            </Map>


            {!isMobile ? rewardFlightDetail : (
                <SideSheet
                    placement='bottom'
                    mask={false}
                    visible={!!selectedDestiantionAirportInfo && !!selectedRoute}
                    style={{ borderRadius: '12px 12px 0 0' }}
                    bodyStyle={{ padding: 0 }}
                    headerStyle={{ padding: 0 }}
                    closable={false}
                    height={284}
                >
                    {rewardFlightDetail}
                </SideSheet>
            )}
        </div>
    );
};

export default AirportMap;