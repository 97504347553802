import { Button, Divider, Form, TextArea, Toast } from "@douyinfe/semi-ui"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import { useCallback, useEffect, useRef, useState } from "react"
import { request } from "../../utils"
import { IconCopy } from "@douyinfe/semi-icons"
import copy from "copy-to-clipboard"
import FlexContainer from "../FlexContainer"

interface IGPTHelperProps {
    system_prompt?: string,
    user_content?: string
}

const GPTHelper = (props: IGPTHelperProps) => {
    const formRef = useRef<FormApi>()

    const [loading, setLoading] = useState(false)
    const [proofreadLoading, setProofreadLoading] = useState(false)

    const [response, setResponse] = useState('')

    const [tokenUsage, setTokenUsage] = useState({
        prompt_tokens: 0,
        completion_tokens: 0,
        total_tokens: 0,
    })

    useEffect(() => {
        formRef.current?.setValues(props)
    }, [props])

    const handleGenerateResponse = async () => {
        const formData = await formRef.current?.getValues()

        if (!formData.system_prompt || !formData.user_content) {
            Toast.error("Invalid form data")
            return
        }

        setLoading(true)
        const respData = await request({
            method: 'post',
            // baseURL: 'http://localhost:7001',
            url: '/api/v1/service/execute',
            data: {
                service: 'openai.createCompletion',
                requestParams: [
                    [
                        {
                            role: 'system',
                            content: [
                                {
                                    type: 'text',
                                    text: formData?.system_prompt
                                },
                            ],
                        },
                        {
                            role: 'user',
                            content: [
                                {
                                    type: 'text',
                                    text: formData?.user_content
                                }
                            ]
                        }
                    ],
                    true
                ]
            }
        })

        setResponse(respData?.response || '')
        setTokenUsage(respData?.tokenUsage || {})
        setLoading(false)
    }

    const handleProofread = useCallback(async () => {
        setProofreadLoading(true)
        const respData = await request({
            method: 'post',
            // baseURL: 'http://localhost:7001',
            url: '/api/v1/service/execute',
            data: {
                service: 'openai.createCompletion',
                requestParams: [
                    [
                        {
                            role: 'system',
                            content: [
                                {
                                    type: 'text',
                                    text: 'proofread'
                                },
                            ],
                        },
                        {
                            role: 'user',
                            content: [
                                {
                                    type: 'text',
                                    text: response
                                }
                            ]
                        }
                    ],
                    true
                ]
            }
        })

        setResponse(respData?.response || '')
        setTokenUsage(respData?.tokenUsage || {})
        setProofreadLoading(false)
    }, [response])

    return (
        <div style={{ padding: '16px 0 24px 0' }}>
            <div className='font-weight-black responsive-text' style={{ fontSize: 20, letterSpacing: -0.5 }}>GPT</div>

            <Form getFormApi={api => formRef.current = api}>
                <Form.Select
                    label="Language"
                    field="language"
                    optionList={[
                        { label: 'English', value: 'english' },
                        { label: 'Chinese', value: 'chinese' }
                    ]}
                    style={{ width: '100%' }}
                />
                <Form.TextArea label="System prompt" field="system_prompt" />
                <Form.TextArea label="User content" field="user_content" />
            </Form>

            <Button theme="solid" onClick={handleGenerateResponse} loading={loading}>Generate response</Button>

            {!!response && (
                <div className="responsive-text">
                    <div style={{ fontSize: 16, letterSpacing: -0.5, fontWeight: 700, margin: '12px 0 4px' }}>Response:</div>
                    <div className="responsive-background" style={{ padding: '8px', borderRadius: 8, position: 'relative' }}>
                        <Button
                            icon={<IconCopy />}
                            style={{ position: 'absolute', top: 8, right: 12, zIndex: 1 }}
                            theme='borderless'
                            onClick={() => {
                                copy(response)
                                Toast.success("Copied to clipboard")
                            }}
                        />

                        <TextArea value={response} borderless rows={20} onChange={setResponse} />

                        <div style={{ padding: '0 12px' }}>
                            <Button theme="borderless" style={{ padding: 0 }} size="small" onClick={handleProofread} loading={proofreadLoading}>Proofread</Button>

                            <Divider style={{ margin: '8px 0' }} />

                            <FlexContainer className="font-weight-bold" flexDirection="column" style={{ fontSize: 12, color: 'grey', lineHeight: '16px' }}>
                                <span>Total tokens: {tokenUsage.total_tokens || 'N/A'}</span>
                                <span>Prompt tokens: {tokenUsage.prompt_tokens || 'N/A'}</span>
                                <span>Completion tokens: {tokenUsage.completion_tokens || 'N/A'}</span>
                            </FlexContainer>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default GPTHelper