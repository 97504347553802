import { IAirport } from "../../../typings"
import { formatNumber } from "../../../utils"

export const calcHaversineDistance = (loc1?: IAirport, loc2?: IAirport, returnNumber = false) => {
    if (!loc1 || !loc2 || !loc1.latitude || !loc1.longitude || !loc2.latitude || !loc2.longitude) {
        if (returnNumber) {
            return 0
        }

        return 'N/A'
    }

    const lat1 = parseFloat(loc1.latitude)
    const lng1 = parseFloat(loc1.longitude)

    const lat2 = parseFloat(loc2.latitude)
    const lng2 = parseFloat(loc2.longitude)

    // Radius of the Earth in kilometers
    const R = 6371;

    // Convert degrees to radians
    const toRadians = (degrees: number) => degrees * (Math.PI / 180);

    // Calculate differences
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lng2 - lng1);

    // Apply the Haversine formula
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in kilometers
    const distance = R * c;

    if (returnNumber) {
        return distance
    }

    return `${formatNumber(distance.toFixed(0))}km`
}

export function estimateFlightDuration(loc1?: IAirport, loc2?: IAirport, avgSpeedKmh = 800) {
    const distanceKm = calcHaversineDistance(loc1, loc2, true) as number

    // Calculate the duration in hours
    const durationHours = distanceKm / avgSpeedKmh;

    // Convert hours to hours and total minutes
    const totalMinutes = Math.round(durationHours * 60);

    // Round the minutes to the nearest 10
    const roundedMinutes = Math.round(totalMinutes / 10) * 10;

    // Convert back to hours and minutes
    const hours = Math.floor(roundedMinutes / 60);
    const minutes = roundedMinutes % 60;

    if (hours === 0) {
        return `${minutes}mins`
    }

    if (minutes === 0) {
        return `${hours}h`
    }

    // Return in "xh ymins" format
    return `${hours}h ${minutes}mins`;
}

export function estimateTimezoneDifference(loc1?: IAirport, loc2?: IAirport) {
    if (!loc1 || !loc2 || !loc1.latitude || !loc1.longitude || !loc2.latitude || !loc2.longitude) {
        return 'N/A'
    }

    const lon1 = parseFloat(loc1.longitude)
    const lon2 = parseFloat(loc2.longitude)

    // Calculate the difference in longitudes
    const lonDifference = lon2 - lon1;

    // Calculate the absolute difference in hours
    const absoluteDifferenceInHours = Math.abs(lonDifference / 15);

    // Determine the sign of the timezone difference
    const sign = lonDifference >= 0 ? '+' : '-';

    // Return formatted result with sign and timezone difference in hours
    return `${sign}${absoluteDifferenceInHours.toFixed(0)} hours`;
}