import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { IDeal } from "../../../typings"
import { useAsyncEffect, useInterval } from "ahooks"
import { request } from "../../../utils"
import { Button, Card, CheckboxGroup, Image, Input, Radio, RadioGroup, Spin, Table } from "@douyinfe/semi-ui"
import FlexContainer from "../../../components/FlexContainer"
import { cloneDeep, omit } from "lodash-es"
import { AppContext } from "../../../App"
import JsBarcode from "jsbarcode"
import ProductCard from "../../GroceriesByPlatform/components/ProductCard"

const PAGE_SIZE = 3

const Label = () => {
    const { windowHeight } = useContext(AppContext)

    const [loading, setLoading] = useState(true)
    const [confirmLoading, setConfirmLoading] = useState(false)

    const [colesProductList, setColesProductList] = useState<IDeal[]>([])
    const [wwsProductList, setWwsProductList] = useState<IDeal[]>([])
    const [matchList, setMatchList] = useState<any[]>([])
    const [counter, setCounter] = useState(0)

    const currOptionRef = useRef<Record<string, string>>({})

    const currBatch = useMemo(() => {
        const outputList = matchList.slice(counter * PAGE_SIZE, (counter + 1) * PAGE_SIZE).filter(item => {
            const sku_id = item.criteria.split('_')[1]

            const targetItem = colesProductList.find(product => product.sku_id === sku_id)

            if (targetItem?.name?.toLowerCase()?.includes('coles')) {
                return false
            }

            return true
        })

        currOptionRef.current = {}

        outputList.forEach(item => {
            const sku_id = item.criteria.split('_')[1]
            const match = item.buying_options.split('||').filter(Boolean)[0]

            currOptionRef.current[sku_id] = match
        })

        return outputList
    }, [counter, matchList, colesProductList])

    useAsyncEffect(async () => {
        const respData1 = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT pic_url, name, sku_id, price FROM simpo_octopus.au_coles_product_tmp`
                ]
            }
        })

        const respData2 = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT pic_url, name, sku_id, price FROM simpo_octopus.au_woolworths_product_tmp`
                ]
            }
        })

        const respData3 = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_gorcery_item_buying_option WHERE platform = "coles" AND criteria LIKE "algolia_%" AND status = 0`
                ]
            }
        })

        setColesProductList(respData1)
        setWwsProductList(respData2)
        setMatchList(respData3)

        setLoading(false)
    }, [])

    const handleSubmit = useCallback(async () => {
        if (currBatch.length === 0) {
            setCounter(prev => prev+1)
            return
        }

        const clonedBatch = cloneDeep(currBatch.map(item => omit(item, ['created_at', 'updated_at'])))

        clonedBatch.forEach(item => {
            const sku_id = item.criteria.split('_')[1]
            const match = currOptionRef.current[sku_id]

            if (match === 'none_of_above') {
                item.status = -1
                return
            }

            if (match === 'save_for_later') {
                return
            }

            item.status = 1
            item.buying_options = match
        })

        setConfirmLoading(true)
        await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.insert',
                requestParams: [
                    `simpo_octopus`, `au_gorcery_item_buying_option`, clonedBatch, { onDuplicateKeyUpdate: {
                        status: 'VALUES(status)',
                        buying_options: 'VALUES(buying_options)'
                    } }
                ]
            }
        })

        setConfirmLoading(false)
        setCounter(prev => prev+1)
    }, [currBatch])

    return (
        <Card style={{ margin: 24, height: `calc(${windowHeight}px - 48px - 52px)`, overflow: 'auto' }} bodyStyle={{ paddingBottom: 0 }}>
            <Table
                loading={loading}
                pagination={false}
                dataSource={currBatch}
                sticky
                columns={[
                    {
                        title: 'Product',
                        render: (record: any) => {
                            const sku_id = record.criteria.split('_')[1]
                            const colesProductDetail = colesProductList.find(item => item.sku_id === sku_id)

                            return (
                                <div>
                                    <div>
                                        <Image src={colesProductDetail?.pic_url} width="200px" />
                                    </div>
                                    <div>{colesProductDetail?.name} (${colesProductDetail?.price})</div>
                                </div>
                            )
                        }
                    },
                    {
                        title: 'Matches',
                        render: (record: any) => {
                            const sku_id = record.criteria.split('_')[1]
                            const matchSkuIdList = record.buying_options.split('||').filter(Boolean)

                            return (
                                <RadioGroup 
                                    key={record.criteria} 
                                    type='card' 
                                    buttonSize='small' 
                                    defaultValue={matchSkuIdList[0]} 
                                    style={{ display: 'flex', flexDirection: 'column', gap: 0 }}
                                    onChange={e => currOptionRef.current[sku_id] = e.target.value}
                                >
                                    {matchSkuIdList.map((sku_id: number) => {
                                        const wwsProductDetail = wwsProductList.find(item => item.sku_id === sku_id)

                                        return (
                                            <Radio key={sku_id} value={sku_id} style={{ padding: '5px 0 0 12px' }}>
                                                <FlexContainer alignItems="center" gap="12px">
                                                    <div>
                                                        <Image src={wwsProductDetail?.pic_url} width="80px" />
                                                    </div>
                                                    <div>{wwsProductDetail?.name} (${wwsProductDetail?.price})</div>
                                                </FlexContainer>
                                            </Radio>
                                        )
                                    })}

                                    <Radio value="none_of_above">None of above</Radio>
                                    <Radio value="save_for_later">Save for later</Radio>
                                </RadioGroup>
                            )
                        }
                    }
                ]}
            />

            <FlexContainer justifyContent="flex-end" style={{ background: 'white', position: 'sticky', bottom: 0, padding: '8px 0', borderTop: '1px solid #eaeaea' }}>
                <FlexContainer alignItems="center" gap="16px">
                    <Input onChange={v => setCounter(parseInt(v as string))} style={{ width: 60 }} />
                    <span>
                        {counter+1} / {Math.ceil(matchList.length / PAGE_SIZE)}
                    </span>
                    <Button loading={confirmLoading} size="large" theme="solid" onClick={handleSubmit}>Submit and continue ({currBatch.length})</Button>
                </FlexContainer>
            </FlexContainer>
        </Card>
    )
}

export const LabelV2 = () => {
    const [productList, setProductList] = useState<IDeal[]>([])
    const [productIndex, setProductIndex] = useState(0)

    const [loading, setLoading] = useState(true)

    const isManualRef = useRef(false)

    useAsyncEffect(async () => {
        const respData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT pic_url, name, sku_id, price, barcode FROM simpo_octopus.au_woolworths_product_tmp WHERE barcode <> "" AND barcode is not null AND name NOT LIKE "%woolworths%" LIMIT 100000 OFFSET 1000`
                ]
            }
        })

        setProductList(respData)
        setLoading(false)
    }, [])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (isManualRef.current) {
                return
            }

            const respData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT value FROM simpo_octopus.config WHERE \`key\` = "auto_scan_product_index"`
                    ]
                }
            })

            setProductIndex(parseInt(respData?.[0]?.value || '0'))
        }, 1000)

        return () => {
            interval && clearInterval(interval)
        }
    }, [])

    const currProduct = useMemo(() => {
        return productList[productIndex]
    }, [productList, productIndex])

    useAsyncEffect(async () => {
        if (!currProduct) {
            return
        }

        setLoading(true)

        JsBarcode('#barcode', currProduct?.barcode || '')

        await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `UPDATE simpo_octopus.config SET value = "${currProduct.sku_id}" WHERE \`key\` = "auto_scan_product_sku_id"`
                ]
            }
        })

        setLoading(false)
    }, [currProduct])

    return (
        <Spin spinning={loading}>
            <FlexContainer justifyContent="center" alignItems="center" style={{ height: window.innerHeight - 52, visibility: loading ? 'hidden' : 'visible' }}>
                <FlexContainer flexDirection="column" alignItems="center" gap="8px" style={{ width: 400 }}>

                    {currProduct && <ProductCard platform="woolworths" product={currProduct} />}
                    <canvas id="barcode" />

                    <FlexContainer gap="8px" alignItems="center" style={{ marginTop: 16 }}>
                        <span className="font-weight-bold" style={{ fontSize: 14 }}>{productIndex + 1} / {productList.length}</span>

                        <Button disabled={productIndex === 0} onClick={() => {
                            isManualRef.current = true
                            setProductIndex(prev => prev-1)
                        }}>Previous</Button>
                        <Button theme="solid" disabled={productIndex === productList.length - 1} onClick={() => {
                            isManualRef.current = true
                            setProductIndex(prev => prev+1)
                        }}>Next</Button>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        </Spin>
    )
}

export default Label