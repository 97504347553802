import { Button, Calendar, Card, Dropdown, Input, Radio, RadioGroup, Select, SideSheet, Slider, Table, Tag, Toast, Typography } from "@douyinfe/semi-ui"
import SubHeader from "../../components/SubHeader"
import './index.less'
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useAsyncEffect } from "ahooks"
import { formatNumber, generateColorGradient, getUserFriendlyTextColor, renderPageOnReady, request } from "../../utils"
import { IAirport, IRewardSeatSummary } from '../../typings'
import { ColumnProps } from "@douyinfe/semi-ui/lib/es/table"
import FlexContainer from "../../components/FlexContainer"
import AirportRouteSidesheet from "./components/AirportRouteSidesheet"
import _, { debounce, isEmpty, uniq } from "lodash-es"
import AirportMap, { VIEW_STATE_DICT } from "./components/AirportMap"
import dayjs from "dayjs"
import { IconFilter, IconListView, IconMapPin, IconSearch } from "@douyinfe/semi-icons"
import { AppContext } from "../../App"
import { useNavigate } from "react-router-dom"
import DestinationExplorer from "./components/DestinationExplorer"
import useSyncTaskStatus from "../../hooks/useSyncTaskStatus"
import QuickTapDetector from "../../components/QuickTapDetector"

export const RewardFlightContext = createContext<{
    airportDict: Record<string, IAirport>;
    showRouteDetail: (route: IRewardSeatSummary) => void;
}>({
    airportDict: {},
    showRouteDetail: () => { },
})

export const NotAvailableTag = () => (
    <Tag style={{ background: "rgb(108,117,125)", color: 'white' }}>
        <span className="font-weight-bold">Not available</span>
    </Tag>
)

const RewardFlightsByPlatform = () => {
    const [loading, setLoading] = useState(true)

    const { isMobile, isDarkMode, windowHeight } = useContext(AppContext)
    const navigate = useNavigate()

    const { syncTaskStatusDict } = useSyncTaskStatus(['au-virgin-australia', 'au-qantas'])

    const qantasSyncTask = syncTaskStatusDict?.['au-qantas']?.[0]
    const virginAustraliaSyncTask = syncTaskStatusDict?.['au-virgin-australia']?.[0]

    const [systemStatusVisible, setSystemStatusVisible] = useState(false)

    const [departureAirport, setDepartureAirport] = useState('SYD')

    const [airportDict, setAirportDict] = useState<Record<string, IAirport>>({})
    const [rewardSeatList, setRewardSeatList] = useState<{
        routeKey: string;
        rewardSeatList: IRewardSeatSummary[];
    }[]>([])

    const [airportRouteSidesheetProps, setAirportRoutesSidesheetProps] = useState<{ visible: boolean; routeInfo?: IRewardSeatSummary }>({
        visible: false
    })

    const [filterSectionSidesheetVisible, setFilterSectionSidesheetVisible] = useState(false)
    const [filter, setFilter] = useState({
        from: 'SYD',
        to: 'Anywhere',
        dates: JSON.stringify([dayjs().format('YYYY-MM-DD'), dayjs().add(30, 'day').format('YYYY-MM-DD')]),
        ff: 'QF',
        pointRange: [0, 10000000]
    })

    const [viewState, setViewState] = useState(VIEW_STATE_DICT.Australia)
    const [mode, setMode] = useState('map')
    const [searchKeyword, setSearchKeyword] = useState('')

    const filteredRewardSeatList = useMemo(() => {
        return rewardSeatList.filter(item => {
            if (!searchKeyword) {
                return item
            }

            const targetSeat = item.rewardSeatList?.[0]
            const targetAirportDetail = airportDict[targetSeat.destination_airport_code]

            return targetSeat.destination_airport_code.includes(searchKeyword.toUpperCase())
                || targetAirportDetail?.city_name?.toLowerCase().includes(searchKeyword.toLowerCase())
                || targetAirportDetail?.country_name?.toLowerCase().includes(searchKeyword.toLowerCase())
        })
    }, [rewardSeatList, searchKeyword, airportDict])

    useEffect(() => {
        console.log({ syncTaskStatusDict }, 1111)
    }, [syncTaskStatusDict])

    useAsyncEffect(async () => {
        setLoading(true)

        const airportListRespData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    "SELECT * FROM simpo_octopus.au_airport"
                ]
            }
        })

        setAirportDict(Object.assign({}, ...airportListRespData.map((airport: IAirport) => ({
            [airport.airport_code]: airport
        }))))
    }, [])

    useEffect(() => {
        if (isEmpty(airportDict)) return
        handleFetchRewardSeatList({
            ff: 'VA',
            from: 'SYD',
            to: 'Anywhere',
            dates: [dayjs().format('YYYY-MM-DD'), dayjs().add(30, 'day').format('YYYY-MM-DD')],
            pointRange: [0, 10000000]
        }, true)
    }, [airportDict])

    const handleFetchRewardSeatList = useCallback(async (filter: any, disableToast = false) => {
        setLoading(true)

        setDepartureAirport(filter.from)

        const airportList = Object.values(airportDict)
        const filteredAirportCodeList = airportList.filter(item => item.region_name === filter.to).map(item => item.airport_code)

        filter.dates = typeof filter.dates === 'string' ? JSON.parse(filter.dates) : filter.dates

        const rewardSeatListRespData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT
                        a.*,
                        b.carrier,
                        departure_airport_code,
                        destination_airport_code 
                    FROM
                        (
                            SELECT
                                route_key,
                                cabin_class,
                                min( fare_pts_price ) AS min_fare_pts_price 
                            FROM
                                simpo_octopus.au_reward_seat 
                            WHERE
                                brand_id IN ( 'RE', 'RP', 'RB' ) 
                                AND route_key LIKE '${filter.ff}_${filter.from}_%'
                                AND departure_date >= "${filter.dates[0]}"
                                AND departure_date <= "${filter.dates[1]}"
                                AND fare_pts_price <= ${filter.pointRange[1]}
                            GROUP BY
                                route_key,
                                cabin_class 
                            ORDER BY
                                min_fare_pts_price 
                        ) a,
                        simpo_octopus.au_airport_route b 
                    WHERE
                        a.route_key = b.uniq_composite_key
                        ${filter.to === 'Anywhere'
                        ? ""
                        : `AND b.destination_airport_code IN (${filteredAirportCodeList.map(item => `"${item}"`).join(', ')})`}`
                ]
            }
        })

        const routeDict = _.groupBy(rewardSeatListRespData, 'route_key')

        setRewardSeatList(Object.entries(routeDict).map(([routeKey, rewardSeatList]) => {
            return {
                routeKey,
                rewardSeatList
            }
        }))

        !disableToast && Toast.success('Reward seat updated')
        setLoading(false)
    }, [airportDict])

    const columns: ColumnProps<any>[] = [
        {
            title: 'Flights',
            // width: 170,
            render: (item) => {
                const record = item.rewardSeatList?.[0]
                const departureAirportInfo = airportDict[record.departure_airport_code]
                const destinationAirportInfo = airportDict[record.destination_airport_code]


                return (
                    <FlexContainer alignItems="center" gap="30px">
                        <div>
                            <div className="font-weight-bold" style={{ fontWeight: 700, fontSize: 16, marginBottom: -4 }}>{record.departure_airport_code}</div>
                            <Typography.Paragraph type="tertiary" style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                                <span className="font-weight-regular">{departureAirportInfo?.city_name}, {departureAirportInfo?.country_name}</span>
                            </Typography.Paragraph>
                        </div>


                        <div style={{ fontSize: 20 }}>
                            <img width="24" height="24" src={`https://img.icons8.com/ios-filled/96/${isDarkMode ? "FFFFFF" : "000000"}/airplane-take-off.png`} alt="airplane-take-off" />
                        </div>

                        <div>
                            <div className="font-weight-bold" style={{ fontWeight: 700, fontSize: 16, marginBottom: -4 }}>{record.destination_airport_code}</div>
                            <Typography.Paragraph type="tertiary" style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                                <span className="font-weight-regular">{destinationAirportInfo?.city_name}, {destinationAirportInfo?.country_name}</span>
                            </Typography.Paragraph>
                        </div>
                    </FlexContainer>
                )
            }
        },
        {
            title: 'Economy',
            width: 120,
            render: (item) => {
                const record = item.rewardSeatList.find((seat: IRewardSeatSummary) => seat.cabin_class === 'Economy')

                if (!record) {
                    return (
                        <div>
                            <NotAvailableTag />
                        </div>
                    )
                }

                const bgColor = getColorFn(record.min_fare_pts_price)

                return (
                    <div>
                        <Tag style={{ background: bgColor, color: 'white' }}>
                            <span className="font-weight-bold">{formatNumber(record.min_fare_pts_price)}</span>
                        </Tag>
                    </div>
                )
            }
        },
        // {
        //     title: 'Premium',
        //     render: (item) => {
        //         const record = item.rewardSeatList.find((seat: IRewardSeatSummary) => seat.cabin_class === 'PremiumEconomy')

        //         if (!record) {
        //             return (
        //                 <div>
        //                     <NotAvailableTag />
        //                 </div>
        //             )
        //         }

        //         return (
        //             <div>
        //                 <Tag style={{ background: "rgb(25,135,84)", color: 'white' }}>
        //                     <span className="font-weight-bold">{formatNumber(record.min_fare_pts_price)}</span>
        //                 </Tag>
        //             </div>
        //         )
        //     }
        // },
        {
            title: 'Business',
            width: 120,
            render: (item) => {
                const record = item.rewardSeatList.find((seat: IRewardSeatSummary) => seat.cabin_class === 'Business')

                if (!record) {
                    return (
                        <div>
                            <NotAvailableTag />
                        </div>
                    )
                }

                const bgColor = getColorFn(record.min_fare_pts_price)

                return (
                    <div>
                        <Tag style={{ background: bgColor, color: 'white' }}>
                            <span className="font-weight-bold">{formatNumber(record.min_fare_pts_price)}</span>
                        </Tag>
                    </div>
                )
            }
        },
        {
            title: '',
            width: 60,
            render: (item) => {
                const record = item.rewardSeatList?.[0]

                return (
                    <Button theme="solid" icon={<IconSearch />} type="tertiary" style={{ borderRadius: 16 }} onClick={() => {
                        // setAirportRoutesSidesheetProps({ visible: true, routeInfo: record })
                        navigate(`/reward-flights/${record.departure_airport_code}/${record.destination_airport_code}`)
                    }} />
                )
            }
        }
    ]

    const handleRow = (index: number) => {
        // 给偶数行设置斑马纹
        if (index % 2 === 0) {
            return {
                style: {
                    background: 'var(--semi-color-fill-0)',
                },
            };
        } else {
            return {};
        }
    };

    const departureAirportOptionList = useMemo(() => {
        const codeArr = ["SYD", 'MEL', 'ADL', 'BNE', 'CNS', 'PER']
        const nameArr = ['Sydney', 'Melbourne', 'Adelaide', 'Brisbane', 'Cairns', 'Perth']

        return codeArr.map((item, idx) => ({
            label: <span className="font-weight-bold">{nameArr[idx]}</span>,
            value: item
        }))
    }, [])

    const regionOptionList = useMemo(() => {
        const uniqRegionList = uniq(Object.values(airportDict).map(item => item.region_name))

        return [
            {
                label: <span className="font-weight-bold">Anywhere</span>,
                value: 'Anywhere'
            },
            ...uniqRegionList.map(item => ({
                label: <span className="font-weight-bold">{item}</span>,
                value: item
            }))
        ]
    }, [airportDict])

    const travelDateOptionList = useMemo(() => {
        return [
            {
                label: <span className="font-weight-bold">Within one month</span>,
                value: JSON.stringify([dayjs().format('YYYY-MM-DD'), dayjs().add(30, 'day').format('YYYY-MM-DD')])
            },
            {
                label: <span className="font-weight-bold">Within three month</span>,
                value: JSON.stringify([dayjs().format('YYYY-MM-DD'), dayjs().add(90, 'day').format('YYYY-MM-DD')])
            },
            {
                label: <span className="font-weight-bold">Within six month</span>,
                value: JSON.stringify([dayjs().format('YYYY-MM-DD'), dayjs().add(90, 'day').format('YYYY-MM-DD')])
            }
        ]
    }, [])

    const ffOptionList = useMemo(() => {
        return [
            {
                label: <span className="font-weight-bold">Virgin Australia</span>,
                value: 'VA'
            },
            {
                label: <span className="font-weight-bold">Qantas</span>,
                value: 'QF'
            }
        ]
    }, [])

    const pointRange = useMemo(() => {
        const priceList = rewardSeatList.map(route => route.rewardSeatList.map(seat => seat.min_fare_pts_price)).flat()

        if (priceList.length === 0) {
            return [0, 0]
        }

        const minPrice = Math.min(...priceList)
        const maxPrice = Math.max(...priceList)

        return [minPrice, maxPrice]
    }, [rewardSeatList])

    const getColorFn = useCallback(generateColorGradient(pointRange[0], pointRange[1], '#198754', '#fc8800'), [pointRange])

    const getTipFormatter = useCallback((v: any) => {
        const diff = pointRange[1] - pointRange[0]

        return <span className="font-weight-bold">{formatNumber(parseInt(`${pointRange[0] + diff * v / 100}`))}</span>
    }, [pointRange])

    const filterSection = (
        <FlexContainer className="filter-bar-wrapper" flexWrap="wrap" style={{ width: '100%', height: 'fit-content' }}>
            <Select
                showArrow={false}
                className="custom-selector-wrapper responsive-border"
                optionList={departureAirportOptionList}
                defaultValue={filter.from}
                prefix={<span className="font-weight-bold">From</span>}
                onChange={value => setFilter(prev => ({ ...prev, from: value as string }))}
            />
            <Select
                showArrow={false}
                className="custom-selector-wrapper responsive-border"
                defaultValue={filter.to}
                optionList={regionOptionList}
                prefix={<span className="font-weight-bold">To</span>}
                onChange={value => setFilter(prev => ({ ...prev, to: value as string }))}
            />
            <Select
                showArrow={false}
                className="custom-selector-wrapper responsive-border"
                defaultValue={filter.dates}
                optionList={travelDateOptionList as any}
                prefix={<span className="font-weight-bold">Travel dates</span>}
                onChange={value => setFilter(prev => ({ ...prev, dates: value as string }))}
            />
            <Select
                showArrow={false}
                className="custom-selector-wrapper responsive-border"
                defaultValue={filter.ff}
                optionList={ffOptionList}
                prefix={<span className="font-weight-bold">Frequent flyer</span>}
                onChange={value => setFilter(prev => ({ ...prev, ff: value as string }))}
            />

            <div className="responsive-border" style={{ width: '100%', border: '1px solid #ddd', padding: '10px 16px 12px 16px', marginTop: 12, borderRadius: 4 }}>
                <FlexContainer className="font-weight-bold" style={{ fontSize: 12, marginBottom: -4, color: 'grey' }}>
                    <span>Points budget: {filter.pointRange[1] === 10000000 ? 'Unlimited' : `${formatNumber(filter.pointRange[1])} pts`}</span>
                </FlexContainer>
                <Slider defaultValue={100} tipFormatter={v => getTipFormatter(v)} marks={{ '0': formatNumber(pointRange[0]), '100': formatNumber(pointRange[1]) }} onChange={(v: any) => {
                    const diff = pointRange[1] - pointRange[0]
                    setFilter(prev => ({ ...prev, pointRange: [pointRange[0], pointRange[0] + diff * v / 100] }))
                }}></Slider>
            </div>
            <Button loading={loading} block size="large" theme="solid" style={{ marginTop: 12 }} onClick={async () => {
                setViewState((VIEW_STATE_DICT as any)[filter.to] || undefined)
                await handleFetchRewardSeatList(filter)
                setFilterSectionSidesheetVisible(false)
            }
            }>Update search</Button>
        </FlexContainer>
    )

    return (
        <RewardFlightContext.Provider value={{
            airportDict,
            showRouteDetail: (v) => {
                // setAirportRoutesSidesheetProps({ visible: true, routeInfo: v })}
                navigate(`/reward-flights/${v.departure_airport_code}/${v.destination_airport_code}`)
            }
        }}>
            <div className="reward-flight-wrapper" style={{ height: '100%', overflow: 'auto', position: 'relative' }}>

                <FlexContainer style={{ width: '100%' }}>
                    {!isMobile && (
                        <>
                            <FlexContainer
                                className="responsive-background responsive-border"
                                flexDirection="column"
                                style={{ width: 300, flexShrink: 0, padding: 24, background: 'white', borderRight: '1px solid #eaeaea' }}
                                gap="20px"
                                justifyContent="space-between"
                            >
                                {filterSection}

                                <QuickTapDetector onFiveTaps={() => setSystemStatusVisible(true)}>
                                    <div className="responsive-text" style={{ fontSize: 12, visibility: systemStatusVisible ? 'visible' : 'hidden' }}>
                                        {!!qantasSyncTask && (
                                            <div>
                                                Qantas data syncd at {dayjs(qantasSyncTask.created_at).format('MM-DD HH:mm')} ({qantasSyncTask.progress} / {qantasSyncTask.total})
                                            </div>
                                        )}

                                        {!!virginAustraliaSyncTask && (
                                            <div>
                                                Virgin Australia data syncd at {dayjs(virginAustraliaSyncTask.created_at).format('MM-DD HH:mm')} ({virginAustraliaSyncTask.progress} / {virginAustraliaSyncTask.total})
                                            </div>
                                        )}
                                    </div>
                                </QuickTapDetector>

                            </FlexContainer>

                            {mode === 'map' && (
                                <FlexContainer gap="8px" style={{ position: 'absolute', top: 10, right: 24, zIndex: 1 }}>
                                    <Input className="map-search-input-wrapper" showClear placeholder="Search for airport, city, country..." style={{ width: 300, borderRadius: 16, background: 'white' }} prefix={<IconSearch />} onChange={debounce(setSearchKeyword, 300)} />

                                    <Button className="responsive-text__opposite" type="tertiary" style={{ background: 'white', borderRadius: 16 }} icon={<IconListView />} onClick={() => setMode('table')}>
                                        Show in table
                                    </Button>
                                </FlexContainer>
                            )}
                        </>
                    )}

                    {isMobile && mode === 'map' && (
                        <FlexContainer gap="8px" style={{ position: 'absolute', top: 10, right: 16, zIndex: 1 }}>
                            <Button style={{ background: 'white', color: 'black', borderRadius: 16 }} icon={<IconFilter />} onClick={() => setFilterSectionSidesheetVisible(true)}>
                                Filter
                            </Button>

                            <Button style={{ background: 'white', color: 'black', borderRadius: 16 }} icon={<IconListView />} onClick={() => setMode('table')}>
                                Show in table
                            </Button>
                        </FlexContainer>
                    )}

                    {mode === 'map' && <AirportMap key={JSON.stringify(filteredRewardSeatList)} departureAirport={departureAirport} viewState={viewState} routeList={filteredRewardSeatList} />}

                    {mode === 'table' && (
                        <div style={{ width: '100%', height: `calc(${windowHeight}px - 52px)`, overflow: 'auto', position: 'relative' }}>
                            <FlexContainer className="responsive-background" flexDirection={isMobile ? "column" : 'row'} justifyContent="space-between" style={{ padding: `10px ${isMobile ? 12 : 24}px 10px 12px`, position: 'sticky', top: 0, background: '#f2f3f5', zIndex: 1 }}>
                                {!isMobile && (
                                    <Input showClear placeholder="Search for airport, city, country..." style={{ width: 300, borderRadius: 16 }} prefix={<IconSearch />} onChange={debounce(setSearchKeyword, 300)} />
                                )}

                                <div>
                                    {isMobile && <Button type="tertiary" icon={<IconFilter />} style={{ borderRadius: 16, marginRight: 8 }} onClick={() => setFilterSectionSidesheetVisible(true)}>Filter</Button>}
                                    <Button type="tertiary" icon={<IconMapPin />} style={{ borderRadius: 16 }} onClick={() => setMode("map")}>Show in map</Button>
                                </div>

                                {isMobile && (
                                    <Input showClear placeholder="Search for airport, city, country..." style={{ width: '100%', borderRadius: 16, marginTop: 8 }} prefix={<IconSearch />} onChange={debounce(setSearchKeyword, 300)} />
                                )}
                            </FlexContainer>

                            <div style={{ position: 'relative' }}>
                                <Table
                                    size="small"
                                    loading={loading}
                                    // sticky
                                    onRow={(record, idx) => handleRow(idx as number)}
                                    empty={<span className="font-weight-regular">No data</span>}
                                    dataSource={filteredRewardSeatList}
                                    columns={columns}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    )}
                </FlexContainer>

                <AirportRouteSidesheet {...airportRouteSidesheetProps} onCancel={() => setAirportRoutesSidesheetProps({ visible: false })} />
            </div>

            <SideSheet width="100%" visible={filterSectionSidesheetVisible} onCancel={() => setFilterSectionSidesheetVisible(false)}>
                {filterSection}
            </SideSheet>
        </RewardFlightContext.Provider>
    )
}

export default RewardFlightsByPlatform