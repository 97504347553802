import { Button, Card, Divider, Form, RadioGroup, TextArea, Toast } from "@douyinfe/semi-ui"
import { useCallback, useContext, useState } from "react"
import FlexContainer from "../../../components/FlexContainer"
import './index.less'
import { request } from "../../../utils"
import { AppContext } from "../../../App"
import { IconDelete } from "@douyinfe/semi-icons"
import { Editor } from "@monaco-editor/react"

const QUERY_HISTORY_LOCAL_STORAGE_KEY = '__service_list__'

const ServiceRunner = () => {
    const { windowHeight, navbarHeight, isDarkMode } = useContext(AppContext)

    const [historyList, setHistoryList] = useState<{ service: string, requestParams: any }[]>(JSON.parse(localStorage.getItem(QUERY_HISTORY_LOCAL_STORAGE_KEY) || '[]'))

    const [loading, setLoading] = useState(false)

    const [endpoint, setEndpoint] = useState('https://www.hotaudeals.com')
    const [requestPayload, setRequestPayload] = useState('')
    const [response, setResponse] = useState('')

    const handleExecute = useCallback(async () => {
        let json: any

        try {
            json = JSON.parse(requestPayload)
        } catch (err) {
            Toast.error("Invalid request payload")
            return
        }

        setLoading(true)

        try {
            const respData = await request({
                method: 'post',
                baseURL: endpoint,
                url: '/api/v1/service/execute',
                data: json
            })

            if (respData && typeof respData === 'object') {
                setResponse(JSON.stringify(respData, null, 4))
            } else if (typeof respData === 'string') {
                setResponse(respData)
            } else {
                setResponse('')
            }

            const isNewSql = !historyList.find(item => item.service === json.service)

            if (isNewSql) {
                const arr = [
                    json,
                    ...historyList,
                ]
                localStorage.setItem(QUERY_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(arr))
                setHistoryList(arr)
            }
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }, [requestPayload, historyList, endpoint])

    const handleDelete = useCallback((service: string) => {
        const arr = historyList.filter(item => item.service !== service)

        localStorage.setItem(QUERY_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(arr))
        setHistoryList(arr)
    }, [historyList])

    return (
        <FlexContainer className="sql-runner-wrapper" gap="16px" style={{ padding: '8px 16px', width: '100%' }}>
            <FlexContainer gap="8px" flexDirection="column" style={{ width: '100%', height: windowHeight - navbarHeight, overflow: 'auto' }}>
                <FlexContainer gap="12px" alignItems="center">
                    <div className='font-weight-black responsive-text' style={{ fontSize: 20, letterSpacing: -0.5 }}>
                        Service Runner
                    </div>

                    <div>
                        <RadioGroup
                            value={endpoint}
                            onChange={e => setEndpoint(e.target.value)}
                            options={[
                                { label: 'Sydney Prod', value: 'https://www.hotaudeals.com' },
                                { label: 'Shanghai Prod', value: 'http://124.71.152.240:7001' },
                                { label: 'Local', value: 'http://localhost:7001' }
                            ]}
                        />
                    </div>
                </FlexContainer>
                <FlexContainer flexDirection="column" gap="12px">
                    <div style={{ borderRadius: 8, overflow: 'hidden', resize: 'vertical', height: 100 }}>
                        <Editor
                            theme={isDarkMode ? "vs-dark" : 'light'}
                            language="json"
                            height="120px"
                            options={{ minimap: { enabled: false } }}
                            value={requestPayload || JSON.stringify({
                                service: '',
                                requestParams: [

                                ]
                            }, null, 4)}
                            onChange={v => setRequestPayload(v as any)}
                        />                    
                    </div>




                    <FlexContainer>
                        <Button loading={loading} theme="solid" onClick={handleExecute}>Execute</Button>
                    </FlexContainer>
                </FlexContainer>

                {response && (
                    <>
                        <Divider style={{ margin: '8px 0 0' }} />

                        <div className="responsive-text">Response:</div>

                        <div className="responsive-background-secondary responsive-text" style={{ borderRadius: 4, overflow: 'auto' }}>
                            <Editor theme={isDarkMode ? "vs-dark" : 'light'} height={`${windowHeight - navbarHeight - 213 - 16 - 32}px`} defaultLanguage="json" value={response} />
                        </div>
                    </>
                )}
            </FlexContainer>

            <FlexContainer flexDirection="column" gap="4px" style={{ width: 250, flexShrink: 0, borderLeft: '1px solid var(--semi-color-border)', paddingLeft: 16 }}>
                <div>
                    <div className="font-weight-black responsive-text" style={{ fontSize: 16, letterSpacing: -0.5 }}>History queries</div>
                </div>

                <FlexContainer flexDirection="column" gap="8px">
                    {historyList.map(item => {
                        return (
                            <div key={item.service} style={{ cursor: 'pointer' }} onClick={() => setRequestPayload(JSON.stringify(item, null, 4))}>
                                <Card className="responsive-background-secondary" bodyStyle={{ padding: '8px 12px', position: 'relative' }}>
                                    <div style={{ fontFamily: 'monospace', fontWeight: 600, fontSize: 12, lineHeight: '16px', wordBreak: 'break-word' }}>
                                        <Button icon={<IconDelete style={{ fontSize: 12 }} />} theme="borderless" type="danger" size="small" style={{ position: 'absolute', top: 4, right: 4 }} onClick={() => handleDelete(item.service)} />
                                        {item.service}
                                    </div>
                                </Card>
                            </div>
                        )
                    })}
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    )
}

export default ServiceRunner