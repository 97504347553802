import Lottie from "react-lottie-player"
import { json } from "./lottie"

const LoadingSpinner = () => {
    return (
        <Lottie
            loop
            animationData={json}
            play
            // style={{ width: 150, height: 150 }}
        />
    )
}

export default LoadingSpinner