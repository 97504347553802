export const md = `
# 兼顾高利率与消费返现：盘点澳洲值得办的银行卡

觉得有用？加个群吧〜  
**澳洲吃喝玩乐羊毛党**

## 前言

曾几何时，你可能会惊讶于澳洲银行活期存款的利率之高，也可能会诟病线下消费无处不在的刷卡手续费。在这种背景下，探索一种既能提供高利率又支持消费返现的用卡姿态就显得尤为重要。这篇文章将为你盘点澳洲值得办的银行卡，帮助你在日常消费和理财中实现双赢。

## 澳洲值得办的银行卡

以下梳理了目前我持有的澳洲银行卡，请注意，我不是卡奴，给大家推荐的都是“绝世好卡”，供大家抄作业：

- **VFF:** Velocity Frequent Flyer，即维珍航空常旅客计划  
- **QFF:** Qantas Frequent Flyer，即澳洲航空常旅客计划

### 借记卡

#### **HSBC Everyday Global Account (EGA)**

- **年费:** 免费  
- **核心亮点:** 名副其实的买菜卡，线下刷卡返现 2%，跟无处不在的刷卡手续费说拜拜  
  - 免费  
  - 线下刷卡 A$100 以下返现 2%，每月最高返 A$50（需每月转入 A$2000，可转出）

[了解更多](https://www.hsbc.com.au/accounts/products/everyday-global/)

### 跨国转账卡

#### **HSBC Premier**

- **年费:** 免费  
- **核心亮点:** 汇丰卓越理财计划，可实现跨国转账即时到账，且 0 手续费  
  - 不是银行卡，是 HSBC 的会员等级，达标后可免费升级为 Premier  
  - 在中国有较高的开卡成本（日均资产达到 50w），但在澳洲比较简单，需每月转入 A$9000，可转出  
  - 任一国家拥有 Premier 身份，则全球拥有 Premier 身份

### 存款卡

#### **ING Orange Everyday Account (OEA)**

- **年费:** 免费  
- **核心亮点:** 5.5% 存款利息，A$10w 以下最优解  
  - 每月需存入 A$1000，可转出  
  - 每月刷卡 5 次（可在 Coles/Woolies Split Payment 付 5 次）  
  - 月末资产 > 月初资产，多 1 分也可以

[了解更多](https://campaigns.ing.com.au/refer?code=Jrd452&p=d)

### 信用卡

#### **Amex Velocity Platinum Card**

- **年费:** $375  
- **核心亮点:** 线上线下刷卡通通返现 >1.25% VFF PTS  
  - 每自然年赠送一次澳洲境内往返机票  
  - 开卡前 3 个月消费满 A$3000 赠送 12w Velocity  
  - 刷卡 offer 多，例如 Myer 150-15, JB HiFi 100-10

[了解更多](https://americanexpress.com/en-au/referral/velocity-platinum?ref=zHONGSuvFJ&XL=MIMNS)

#### **HSBC Premier QFF MasterCard (Qantas Rewards)**

- **年费:** 免费  
- **核心亮点:** 线上线下刷卡通通返现 1.5% QFF PTS  
  - 仅 HSBC Premier 可办理，无年费  
  - Mastercard，所有商户都支持

[了解更多](https://www.hsbc.com.au/credit-cards/products/premier/)

## 用卡姿态

- 工资流转  
- 日常消费

---

觉得有用？加个群吧〜  
**澳洲吃喝玩乐羊毛党**


`