import { Spin, Toast } from '@douyinfe/semi-ui'
import axios, { AxiosRequestConfig } from 'axios'
import numeral from 'numeral'
import FlexContainer from '../components/FlexContainer'
import { setupCache } from 'axios-cache-interceptor'
import { IAirport, IDeal } from '../typings'
import md5 from 'blueimp-md5'
import { parse } from 'querystring'

// const baseURL = 'http://localhost:7001'
const baseURL = 'https://www.hotaudeals.com'

const axiosInstance = axios.create(window.location.hostname.includes('hotaudeals') ? {} : {
    baseURL,
})

const instance = setupCache(axiosInstance)

const handleEncode = (data: any) => {
    return encodeURIComponent(JSON.stringify(data || {}))
}

const handleSign = (ts: string, encodedContent: string) => {
    return md5(`${ts}__${encodedContent}__${ts}`).toLowerCase()
}

const handleDecode = (data: any) => {
    return JSON.parse(decodeURIComponent(data))
}

export const request = async (props: AxiosRequestConfig) => {
    try {
        let resp: any

        if (!props.url) {
            props.url = '/api/v1/service/execute'
        }

        if (!props.method) {
            props.method = 'post'
        }

        const isDev = window.location.hostname.includes('localhost') && baseURL.includes('localhost')

        if (isDev) {
            resp = await instance(props)
        } else {
            const ts = Date.now().toString()
            const encode = handleEncode(props.data)
            const sign = handleSign(ts, encode)
    
            resp = await instance({
                ...props,
                url: '/api/wrapper',
                data: {
                    data: encode
                },
                headers: {
                    'x-tt-ts': ts,
                    'x-tt-sign': sign
                }
            })
        }

        if (resp.status !== 200) {
            throw new Error(`invalid HTTP status code, expect 200, received ${resp.status}`)
        }

        if (resp.data?.code !== 0) {
            throw new Error(`invalid response code, expect 0, received ${resp.data?.code}`)
        }

        if (!isDev && resp.data?.data) {
            return handleDecode(resp.data?.data)
        }

        return resp.data?.data
    } catch (err: any) {
        Toast.error(`Failed to request. Error: ${err.message}`)
        throw err
    }
}

export const formatNumber = (str: string | number, format = '0,0') => numeral(str).format(format)

export const renderPageOnReady = (loading: boolean, el: any) => {
    if (loading) {
        return (
            <FlexContainer justifyContent='center' alignItems='center' style={{ width: '100%', height: 200 }}>
                <Spin spinning size="large" />
            </FlexContainer>
        )
    }

    return el
}

export const enumerateDates = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray = [];

    let currentDate = start;
    while (currentDate <= end) {
        dateArray.push(new Date(currentDate).toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
}

export function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Check for mobile device user agents
    return /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}

export function getGroceryItemUrl(product: IDeal, type = 'detail') {
    if (type === 'detail') {
        switch (product.platform) {
            case 'coles':
                return `https://www.coles.com.au/product/any-${product.sku_id}`
            case 'woolworths':
                return `https://www.woolworths.com.au/shop/productdetails/${product.sku_id}`
        }
    } else if (type === 'applink') {
        switch (product.platform) {
            case 'coles':
                const productDetailUrl = `https://www.coles.com.au/product/any-${product.sku_id}`
                return `https://applink.coles.com.au/?cid=cusp:open-in-app:pdp:open-cta&url=${encodeURIComponent(productDetailUrl)}`
            case 'woolworths':
                return `https://www.woolworths.com.au/shop/productdetails/${product.sku_id}`
        }
    }
}

export function toBase64Unicode(str: string) {
    // Encode the string as UTF-8
    let utf8Bytes = new TextEncoder().encode(str);
    // Convert the bytes to a string and then to base64
    let base64String = btoa(String.fromCharCode(...utf8Bytes as any));
    return base64String;
}

export function handleDecodeFilterKey() {
    const queryParams: any = parse(window.location.search?.slice(1) || '')

    if (queryParams && queryParams?.filterKey) {
        try {
            const str = atob(queryParams.filterKey)
            return JSON.parse(str)
        } catch(err) {
            return {}
        }
    }

    return {}
}

export function isNumeric(str: any) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function changeUrlWithQueryParams(newPath: string, queryParams: Record<string, any> = {}) {
    // Create a URL object
    const url = new URL(window.location.origin + newPath);

    // Append query parameters from the queryParams object
    for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
            url.searchParams.set(key, queryParams[key]);
        }
    }

    // Use pushState to change the URL without refreshing
    window.history.pushState({}, '', url);
} 

export const renderText = (text?: string) => {
    if (!text) {
        return <></>
    }

    const formattedText = text.split('\n').map(item => item.trim()).filter(Boolean).join(', ')
    return <span dangerouslySetInnerHTML={{ __html: formattedText || '' }} />
}

type CookieOptions = {
    days?: number;
    domain?: string;
};

const COOKIE_DOMAIN = window.location.hostname.includes('localhost') ? 'localhost' : '.hotaudeals.com'

export const CookieUtil = {
    set: (name: string, value: string, options: CookieOptions = {}): void => {
        const { days } = options;
        let expires = "";

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }

        const domainPart = `; domain=${COOKIE_DOMAIN}`;
        document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/${domainPart}`;
    },

    get: (name: string): string | null => {
        const nameEQ = `${name}=`;
        const cookiesArray = document.cookie.split(';');
        
        for (let i = 0; i < cookiesArray.length; i++) {
            let c = cookiesArray[i].trim();
            if (c.startsWith(nameEQ)) {
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
            }
        }
        return null;
    },

    delete: (name: string): void => {
        const domainPart = `; domain=${COOKIE_DOMAIN}`;
        document.cookie = `${name}=; Max-Age=-99999999; path=/${domainPart}`;
    }
};

export function generateColorGradient(minNum: number, maxNum: number, startColor: string, endColor: string) {
    function hexToRgb(hex: string): [number, number, number] {
        let bigint = parseInt(hex.slice(1), 16);
        return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    }

    function rgbToHex(rgb: [number, number, number]): string {
        return (
            '#' +
            rgb
                .map(x => {
                    const hex = x.toString(16);
                    return hex.length === 1 ? '0' + hex : hex;
                })
                .join('')
        );
    }

    function interpolateColor(color1: [number, number, number], color2: [number, number, number], factor: number = 0.5): [number, number, number] {
        let result: [number, number, number] = [0, 0, 0];
        for (let i = 0; i < 3; i++) {
            result[i] = Math.round(color1[i] + factor * (color2[i] - color1[i]));
        }
        return result;
    }

    const startRGB = hexToRgb(startColor);
    const endRGB = hexToRgb(endColor);

    return function(value: number): string {
        const factor = (value - minNum) / (maxNum - minNum);
        const resultColor = interpolateColor(startRGB, endRGB, factor);
        return rgbToHex(resultColor);
    };
}

export function getUserFriendlyTextColor(backgroundColor: string): string {
    function hexToRgb(hex: string): [number, number, number] {
        let bigint = parseInt(hex.slice(1), 16);
        return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    }

    function luminance(r: number, g: number, b: number): number {
        const a = [r, g, b].map(v => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    function getContrastRatio(luminance1: number, luminance2: number): number {
        return (Math.max(luminance1, luminance2) + 0.05) / (Math.min(luminance1, luminance2) + 0.05);
    }

    const rgb = hexToRgb(backgroundColor);
    const backgroundLuminance = luminance(rgb[0], rgb[1], rgb[2]);

    const whiteLuminance = luminance(255, 255, 255);
    const blackLuminance = luminance(0, 0, 0);

    const whiteContrast = getContrastRatio(backgroundLuminance, whiteLuminance);
    const blackContrast = getContrastRatio(backgroundLuminance, blackLuminance);

    return whiteContrast > blackContrast ? '#FFFFFF' : '#000000';
}

export const waitTime = (duration: number) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(null)
        }, duration)
    })
}