import { memo, useContext, useRef, useState } from "react"
import { IRewardSeatDetail, IRewardSeatSummary } from "../../../../typings"
import { useAsyncEffect } from "ahooks"
import { enumerateDates, formatNumber, generateColorGradient, request } from "../../../../utils"
import { Button, Calendar, Card, Divider, SideSheet, Spin, Table, Tag, Toast } from "@douyinfe/semi-ui"
import { SideSheetReactProps } from "@douyinfe/semi-ui/lib/es/sideSheet"
import { ColumnProps } from "@douyinfe/semi-ui/lib/es/table"
import dayjs from "dayjs"
import FlexContainer from "../../../../components/FlexContainer"
import _, { uniqueId } from 'lodash-es'
import { NotAvailableTag } from "../.."
import { AppContext } from "../../../../App"
import './index.less'
import { IconArrowLeft, IconChevronLeft, IconChevronRight, IconCoinMoneyStroked, IconDoubleChevronLeft } from "@douyinfe/semi-icons"


interface IAirportRouteSidesheetProps extends SideSheetReactProps {
    routeInfo?: IRewardSeatSummary;
    renderDirectly?: boolean;
    mode?: string;
    cabinClass?: string;
}

const AirportRouteSidesheet = ({ routeInfo, renderDirectly, mode, cabinClass, ...sidesheetProps }: IAirportRouteSidesheetProps) => {
    const [rewardSeatGroupByDate, setRewardSeatGroupByDate] = useState<Record<string, IRewardSeatDetail[]>>({})

    const [dateList, setDateList] = useState<string[]>([])
    const [loading, setLoading] = useState(false)

    const [currDate, setCurrDate] = useState(dayjs())

    const { isMobile, windowWidth } = useContext(AppContext)

    const getEconomyColorFn = useRef<any>()
    const getBusinessColorFn = useRef<any>()

    useAsyncEffect(async () => {
        if (!routeInfo) return

        setLoading(true)
        const rewardSeatListRespData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_reward_seat WHERE route_key = "${routeInfo.route_key}" AND brand_id IN ("RE", "RP", "RB") AND departure_date >= CURDATE()`
                ]
            }
        })

        if (rewardSeatListRespData.length) {
            const economyPriceList = rewardSeatListRespData.filter((seat: IRewardSeatDetail) => seat.cabin_class === 'Economy').map((seat: IRewardSeatDetail) => seat.fare_pts_price)
            const businessPriceList = rewardSeatListRespData.filter((seat: IRewardSeatDetail) => seat.cabin_class === 'Business').map((seat: IRewardSeatDetail) => seat.fare_pts_price)
    
            document.dispatchEvent(new CustomEvent('reward-flight-min-price-update', {
                detail: {
                    minEconomyPrice: economyPriceList && economyPriceList.length ? Math.min(...economyPriceList) : -1,
                    minBusinessPrice: businessPriceList && businessPriceList.length ? Math.min(...businessPriceList) : -1
                }
            }))
    
            if (economyPriceList.length) {
                getEconomyColorFn.current = generateColorGradient(Math.min(...economyPriceList), Math.max(...economyPriceList) + 1, '#198754', '#fc8800')
            }
    
            if (businessPriceList.length) {
                getBusinessColorFn.current = generateColorGradient(Math.min(...businessPriceList), Math.max(...businessPriceList) + 1, '#198754', '#fc8800')
            }
    
            const routeDict = _.groupBy(rewardSeatListRespData, 'departure_date')
            const dateList = enumerateDates(dayjs().format('YYYY-MM-DD'), rewardSeatListRespData.slice(-1)[0].departure_date)
    
            setDateList(dateList)
            setRewardSeatGroupByDate(routeDict)
        }

        setLoading(false)
    }, [routeInfo])

    const columns: ColumnProps<any>[] = [
        {
            title: 'Date',
            width: 150,
            render: (date) => {
                return (
                    <span style={{ fontWeight: 600, letterSpacing: -1 }}>{dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD (ddd)')}</span>
                )
            }
        },
        {
            title: '',
            render: () => ''
        },
        {
            title: 'Economy',
            width: 160,
            render: (key: string) => {
                const record = rewardSeatGroupByDate[key]?.find((seat: IRewardSeatDetail) => seat.cabin_class === 'Economy')

                if (!record) {
                    return (
                        <div>
                            <NotAvailableTag />
                        </div>
                    )
                }

                const date = dayjs(record.departure_date, 'YYYY-MM-DD').format('MM-DD-YYYY')

                return (
                    <div style={{ cursor: 'pointer' }}>
                        <Tag style={{ background: getEconomyColorFn.current?.(record.fare_pts_price) || "rgb(25,135,84)", color: 'white' }} onClick={() => {
                            if (routeInfo?.carrier === 'VA') {
                                window.open(`https://book.virginaustralia.com/dx/VADX/#/flight-selection?journeyType=one-way&activeMonth=${date}&direction=0&awardBooking=true&origin=${routeInfo?.departure_airport_code}&destination=${routeInfo?.destination_airport_code}&class=First&ADT=1&CHD=0&INF=0&date=${date}&promoCode=&execution=undefined`)
                            } else {
                                window.open('https://www.qantas.com/')
                            }
                        }}>
                            <span className="font-weight-bold">{formatNumber(record.fare_pts_price)} pts + ${formatNumber(record.tax_cash_price)}</span>
                        </Tag>
                    </div>
                )
            }
        },
        // {
        //     title: 'Premium',
        //     render: (key: string) => {
        //         const record = rewardSeatGroupByDate[key]?.find((seat: IRewardSeatDetail) => seat.cabin_class === 'PremiumEconomy')

        //         if (!record) {
        //             return (
        //                 <div>
        //                     <NotAvailableTag />
        //                 </div>
        //             )
        //         }

        //         const date = dayjs(record.departure_date, 'YYYY-MM-DD').format('MM-DD-YYYY')

        //         return (
        //             <div style={{ cursor: 'pointer' }}>
        //                 <Tag style={{ background: "rgb(25,135,84)", color: 'white' }} onClick={() => {
        //                     if (routeInfo?.carrier === 'VA') {
        //                         window.open(`https://book.virginaustralia.com/dx/VADX/#/flight-selection?journeyType=one-way&activeMonth=${date}&direction=0&awardBooking=true&origin=${routeInfo?.departure_airport_code}&destination=${routeInfo?.destination_airport_code}&class=First&ADT=1&CHD=0&INF=0&date=${date}&promoCode=&execution=undefined`)
        //                     } else {
        //                         window.open('https://www.qantas.com/')
        //                     }
        //                 }}>
        //                     <span className="font-weight-bold">{formatNumber(record.fare_pts_price)} pts + ${formatNumber(record.tax_cash_price)}</span>
        //                 </Tag>
        //             </div>
        //         )
        //     }
        // },
        {
            title: 'Business',
            width: 160,
            render: (key: string) => {
                const record = rewardSeatGroupByDate[key]?.find((seat: IRewardSeatDetail) => seat.cabin_class === 'Business')

                if (!record) {
                    return (
                        <div>
                            <NotAvailableTag />
                        </div>
                    )
                }

                const date = dayjs(record.departure_date, 'YYYY-MM-DD').format('MM-DD-YYYY')

                return (
                    <div style={{ cursor: 'pointer' }}>
                        <Tag style={{ background: getBusinessColorFn.current?.(record.fare_pts_price) || "rgb(25,135,84)", color: 'white' }} onClick={() => {
                            if (routeInfo?.carrier === 'VA') {
                                window.open(`https://book.virginaustralia.com/dx/VADX/#/flight-selection?journeyType=one-way&activeMonth=${date}&direction=0&awardBooking=true&origin=${routeInfo?.departure_airport_code}&destination=${routeInfo?.destination_airport_code}&class=First&ADT=1&CHD=0&INF=0&date=${date}&promoCode=&execution=undefined`)
                            } else {
                                window.open('https://www.qantas.com/')
                            }
                        }}>
                            <span className="font-weight-bold">{formatNumber(record.fare_pts_price)} pts + ${formatNumber(record.tax_cash_price)}</span>
                        </Tag>
                    </div>
                )
            }
        },
        // {
        //     title: 'Action',
        //     width: 80,
        //     render: (record) => {
        //         const record = item.rewardSeatList.find((seat: IRewardSeatDetail) => seat.cabin_class === 'Business')
        //         const date = dayjs(record.departure_date, 'YYYY-MM-DD').format('MM-DD-YYYY')

        //         return (
        //             <Button size="small" theme="borderless" style={{ padding: 0 }} onClick={() => {
        //                 window.open(`https://book.virginaustralia.com/dx/VADX/#/flight-selection?journeyType=one-way&activeMonth=${date}&direction=0&awardBooking=true&origin=${routeInfo?.departure_airport_code}&destination=${routeInfo?.destination_airport_code}&class=First&ADT=1&CHD=0&INF=0&date=${date}&promoCode=&execution=undefined`)
        //             }}>Book</Button>
        //         )
        //     }
        // }
    ]

    if (renderDirectly) {
        if (mode === 'calendar') {
            return (
                <Spin spinning={loading} size="large">
                    <div className="flight-price-calendar-wrapper">
                        <FlexContainer justifyContent="center" className="font-weight-bold responsive-text" style={{ fontSize: 20, letterSpacing: -0.5 }}>{currDate.format('MMMM YYYY')}</FlexContainer>
                        <Divider />
                        <Calendar
                            height={isMobile ? 400 : 500}
                            displayValue={currDate.toDate()}
                            mode="month"
                            dateGridRender={((str: string) => {
                                const targetSeat = rewardSeatGroupByDate[dayjs(str).format('YYYY-MM-DD')]?.filter(item => item.cabin_class.toLowerCase() === cabinClass)?.[0]

                                if (targetSeat) {
                                    const date = dayjs(targetSeat.departure_date, 'YYYY-MM-DD').format('MM-DD-YYYY')

                                    return (
                                        <FlexContainer
                                            className="font-weight-bold"
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{
                                                backgroundColor: (cabinClass === 'economy' ? getEconomyColorFn : getBusinessColorFn).current?.(targetSeat.fare_pts_price) + '66',
                                                position: 'absolute',
                                                left: '0',
                                                right: '0',
                                                top: '0',
                                                bottom: '0',
                                                cursor: 'pointer',
                                                paddingTop: isMobile ? 20 : 0
                                            }}
                                            onClick={() => {
                                                if (routeInfo?.carrier === 'VA') {
                                                    window.open(`https://book.virginaustralia.com/dx/VADX/#/flight-selection?journeyType=one-way&activeMonth=${date}&direction=0&awardBooking=true&origin=${routeInfo?.departure_airport_code}&destination=${routeInfo?.destination_airport_code}&class=First&ADT=1&CHD=0&INF=0&date=${date}&promoCode=&execution=undefined`)
                                                } else {
                                                    window.open('https://www.qantas.com/')
                                                }
                                            }}
                                        >
                                            {formatNumber(targetSeat.fare_pts_price, isMobile ? '0.[0]a' : '0,0')}
                                        </FlexContainer>
                                    )
                                }

                                return (
                                    <FlexContainer
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{
                                            backgroundColor: 'rgba(var(--semi-grey-1), 1)',
                                            position: 'absolute',
                                            left: '0',
                                            right: '0',
                                            top: '0',
                                            bottom: '0',
                                            paddingTop: isMobile ? 20 : 0
                                        }}
                                    >
                                        <span className="font-weight-bold" style={{ opacity: 0.4, fontSize: 16 }}>N/A</span>
                                    </FlexContainer>
                                )
                            }) as any}
                        />

                        <FlexContainer justifyContent="center" style={isMobile ? { marginTop: 16 } : { marginTop: 24, marginBottom: 8 }}>
                            <FlexContainer gap="8px">
                                <Button
                                    size={isMobile ? "default" : "large"}
                                    type="tertiary"
                                    style={{ borderRadius: 32 }}
                                    icon={<IconDoubleChevronLeft />}
                                    disabled={dayjs().format('YYYY-MM-DD') === currDate.format('YYYY-MM-DD')}
                                    onClick={() => setCurrDate(dayjs())}
                                />

                                <Button
                                    size={isMobile ? "default" : "large"}
                                    style={{ borderRadius: 32 }}
                                    icon={<IconChevronLeft />}
                                    disabled={dayjs().format('YYYY-MM-DD') === currDate.format('YYYY-MM-DD')}
                                    onClick={() => setCurrDate(prev => prev.subtract(1, 'month'))}
                                >
                                    Previous Month
                                </Button>
                                <Button
                                    theme="solid"
                                    size={isMobile ? "default" : "large"}
                                    style={{ borderRadius: 32 }}
                                    icon={<IconChevronRight />}
                                    iconPosition="right"
                                    onClick={() => setCurrDate(prev => prev.add(1, 'month'))}
                                >
                                    Next Month
                                </Button>
                            </FlexContainer>
                        </FlexContainer>
                    </div>
                </Spin>
            )
        }

        return (
            <Table
                loading={loading}
                sticky={{ top: -24 }}
                dataSource={dateList as any[]}
                columns={columns}
                pagination={false}
                size="small"
            />
        )
    }

    return (
        <SideSheet
            {...sidesheetProps}
            bodyStyle={{ padding: 0 }}
            width={isMobile ? '100%' : 700}
            title={(
                <FlexContainer gap="8px" style={{ fontWeight: 700 }}>
                    <span>{routeInfo?.departure_airport_code}</span>
                    <span>{"✈️"}</span>
                    <span>{routeInfo?.destination_airport_code}</span>
                </FlexContainer>
            )}
        >
            <Table
                loading={loading}
                sticky
                dataSource={dateList as any[]}
                columns={columns}
                pagination={false}
                size="small"
            />
        </SideSheet>
    )
}

export default AirportRouteSidesheet