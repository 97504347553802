import { Button, Input, MarkdownRender, Modal, Pagination, Table, TextArea } from '@douyinfe/semi-ui';
import './index.less'
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../../App';
import { request } from '../../../../utils';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

interface IGiftCardSummaryProps {
    gift_card_promotion_auto_increment_id: number;
    md: string;
}

const GiftCardSummary = ({ md, gift_card_promotion_auto_increment_id }: IGiftCardSummaryProps) => {
    const [clickCount, setClickCount] = useState(0);
    const [timer, setTimer] = useState<any>(null);
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const [displayMd, setDisplayMd] = useState(md)
    const [newMd, setNewMd] = useState('')
    const { isMobile } = useContext(AppContext)

    const editorRef = useRef<Editor>()

    useEffect(() => {
        setNewMd(displayMd)
    }, [displayMd])

    useEffect(() => {
        // 当点击次数达到5次时，触发动作并重置计数器和计时器
        if (clickCount === 5) {
            setVisible(true)
            clearTimeout(timer);
            setClickCount(0);
        }

        // 清除副作用
        return () => clearTimeout(timer);
    }, [clickCount, timer]);

    const handleClick = () => {
        if (clickCount === 0) {
            // 在第一次点击时，启动计时器
            const newTimer = setTimeout(() => {
                setClickCount(0); // 重置点击计数
            }, 1000); // 设置1秒内点击次数限制
            setTimer(newTimer);
        }

        // 增加点击计数
        setClickCount(prevCount => prevCount + 1);
    };

    const handleOk = useCallback(async () => {
        setLoading(true)

        await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `UPDATE simpo_octopus.au_gift_card_promotion SET promotion_detail = "${encodeURIComponent(newMd)}" WHERE gift_card_promotion_auto_increment_id = "${gift_card_promotion_auto_increment_id}"`
                ]
            }
        })

        setLoading(false)
        setVisible(false)
        setDisplayMd(newMd)
    }, [newMd])

    useEffect(() => {
        // override ul
        const ulElList = Array.from(document.querySelectorAll('.gift-card-summary-wrapper ul'))
        ulElList.map((el: any) => {
            if (el?.innerText?.trim() === '-') {
                el.outerHTML = '-'
            }
        })


        // override a onclick
        const handleClick = (e: any) => {
            e.preventDefault()
            if (e.target.href) {
                window.open(e.target.href)
            }
        }

        const elList = Array.from(document.querySelectorAll('.gift-card-summary-wrapper a'))

        elList.forEach(el => {
            el.addEventListener('click', handleClick)
        })

        return () => {
            elList.forEach(el => {
                el.removeEventListener('click', handleClick)
            })
        }
    }, [displayMd])

    return (
        <div className="gift-card-summary-wrapper" onClick={handleClick}>
            <MarkdownRender
                raw={displayMd.replace(/<br/g, '<br /')}
                format='mdx'
                components={{
                    a: ({ href, children }) => {
                        return (
                            <Button
                                size="small"
                                theme="borderless"
                                style={{ padding: 0, height: 'fit-content' }}
                                onClick={() => {
                                    window.open(href)
                                }}
                            >
                                {children}
                            </Button>
                        )
                    }
                }}
            />

            <Modal visible={visible} title="Edit promotion detail" width={isMobile ? '100%' : 1000} onCancel={() => setVisible(false)} confirmLoading={loading} onOk={handleOk}>
                <Editor
                    initialValue={displayMd}
                    previewStyle={isMobile ? 'tab' : "vertical"}
                    height="600px"
                    initialEditType="wysiwyg"
                    useCommandShortcut={true}
                    ref={editorRef as any}
                    onChange={() => setNewMd(editorRef.current?.getInstance()?.getMarkdown() || '')}
                />
            </Modal>
        </div>
    )
}

export default GiftCardSummary