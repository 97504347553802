import { useContext, useState } from "react"
import { ICashbackPromotion } from "../../../../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../../../utils"
import FlexContainer from "../../../../../components/FlexContainer"
import { Card } from "@douyinfe/semi-ui"
import { Footer } from "../SceneRender"
import { FOOTER_HEIGHT, HEADER_HEIGHT, RADIUS } from "../CatalogueRender"
import dayjs from "dayjs"
import { PosterContext } from "../.."
import { IconFilledArrowUp } from "@douyinfe/semi-icons"
import { REWARD_PROGRAM_MAP } from "../../../../GiftCards/components/PointsCalculator"
import { CASHBACK_PLATFORM_NAME_MAP } from "../../../../Cashback/components/MerchantCashbackSidesheet"
import { AppContext } from "../../../../../App"

const CashbackRender = () => {
    const { COLOR_MAP } = useContext(AppContext)
    const { setTotalPageNum, pageNum } = useContext(PosterContext)
    const [cashbackList, setCashbackList] = useState<ICashbackPromotion[]>([])

    useAsyncEffect(async () => {
        const respData = await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                service: 'rds.sql',
                requestParams: [`
                    SELECT
                        platform,
                        merchant_name,
                        CAST(
                        prev_max_cashback_rate AS DECIMAL ( 10, 2 )) AS prev_max_cashback_rate,
                        CAST(
                        new_max_cashback_rate AS DECIMAL ( 10, 2 )) AS new_max_cashback_rate,
                        new_cashback_info
                    FROM
                        simpo_octopus.au_cashback_promotion_change 
                    WHERE
                        CAST(
                        prev_max_cashback_rate AS DECIMAL ( 10, 2 )) < CAST( new_max_cashback_rate AS DECIMAL ( 10, 2 )) AND created_at >= CURDATE() 
                    ORDER BY
                        CAST(
                        new_max_cashback_rate AS DECIMAL ( 10, 2 )) DESC    
                `]
            }
        })

        const outputList = respData.map((record: any) => {
            const targetItem = record.new_cashback_info.find((item: any) => item.cashback_rate.includes(`${record.new_max_cashback_rate}`))
            console.log(targetItem, 1111)

            const isPercentage = targetItem.cashback_rate.includes('%')
            const cashbackRateDifference = parseFloat((record.new_max_cashback_rate - record.prev_max_cashback_rate).toFixed(2))

            return {
                ...targetItem,
                prev_max_cashback_rate: isPercentage ? `Was <${record.prev_max_cashback_rate}%` : `Was <$${record.prev_max_cashback_rate}`,
                cashback_rate_difference: isPercentage ? `${cashbackRateDifference}%` : `$${cashbackRateDifference}`
            }
        }).filter((item: any) => item.cashback_rate_difference.includes('%') && item.merchant_name.toLowerCase() !== 'big w')

        setCashbackList(outputList)
        setTotalPageNum(Math.ceil(outputList.length / 9))
    }, [])

    return (
        <FlexContainer flexDirection="column" alignItems="center" style={{ height: '100%', width: '100%' }}>
            <div style={{ background: `linear-gradient(to bottom, transparent, rgb(0,100,250) calc(100% - ${HEADER_HEIGHT}px), rgba(0,100,250,0.9) 100%)`, position: 'absolute', height: RADIUS * 2, width: RADIUS * 2, top: 0 - RADIUS * 2 + HEADER_HEIGHT, borderRadius: '50%', zIndex: 0 }} />

            <FlexContainer flexDirection="column" alignItems="center" style={{ position: 'relative', zIndex: 1, height: '100%', width: '100%' }}>
                <FlexContainer flexDirection="column" alignItems="center" style={{ height: HEADER_HEIGHT, color: 'white' }}>
                    <div style={{ fontSize: 36, letterSpacing: -1, fontWeight: 700 }}>
                        Cashback of the Day
                    </div>

                    <div style={{ marginTop: -10, fontSize: 20 }}>
                        {dayjs().format('D MMM YYYY')}
                    </div>
                </FlexContainer>


                <FlexContainer flexDirection="column" justifyContent="center" style={{ padding: '8px 24px', width: '100%', height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`, boxSizing: 'border-box', overflow: 'auto' }}>
                    {/* <div style={{ fontSize: 20, letterSpacing: -1 }}>Available Retailers</div> */}

                    {/* <FlexContainer flexWrap="wrap" gap="8px" >
                        {retailerList.map(item => {
                            return (
                                <Card style={{ width: `calc((100% - 24px) / 4)` }} bodyStyle={{ padding: '4px 8px', height: '100%' }}>
                                    <FlexContainer alignItems="center" style={{ height: '100%' }}>
                                        <img src={item.logo} width="100%" style={{ objectFit: 'contain', maxHeight: 30 }} />
                                    </FlexContainer>
                                </Card>
                            )
                        })}
                    </FlexContainer> */}


                    <FlexContainer flexWrap="wrap" gap="12px">
                        {cashbackList.slice((pageNum - 1) * 9, pageNum * 9).map((cashback) => {
                            return (
                                <FlexContainer key={cashback.merchant_name} flexDirection="column" style={{ width: 'calc((100% - 24px) / 3)' }}>
                                    <Card bodyStyle={{ padding: 12, paddingTop: 0 }} style={{ height: '100%', overflow: 'hidden', position: 'relative' }}>
                                        <div
                                            className="font-weight-bold"
                                            style={{  
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                background: COLOR_MAP[cashback.platform],
                                                color: 'white',
                                                width: '100%',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                height: 14,
                                                lineHeight: '14px',
                                                letterSpacing: -0.5
                                            }}
                                        >
                                            {CASHBACK_PLATFORM_NAME_MAP[cashback.platform]}
                                        </div>

                                        <FlexContainer style={{ height: 72, marginTop: 14 }} alignItems="center">
                                            <img src={cashback.merchant_logo} width="100%" style={{ objectFit: 'contain', maxHeight: 60, borderRadius: 4 }} />
                                        </FlexContainer>

                                        <div className="font-weight-bold" style={{ letterSpacing: -0.5 }}>
                                            <div style={{ whiteSpace: 'nowrap' }}>{cashback.max_cashback_rate.replace('Up to', '').trim()}</div>
                                            <FlexContainer justifyContent="space-between" style={{ whiteSpace: 'nowrap' }}>
                                                <div style={{ fontSize: 11.5, textDecoration: 'line-through', color: 'grey' }}>{cashback.prev_max_cashback_rate}</div>
                                                <FlexContainer style={{ fontSize: 11.5, color: 'rgb(249,57,32)' }} alignItems="center">
                                                    <IconFilledArrowUp style={{ fontSize: 12 }} />
                                                    {cashback.cashback_rate_difference}
                                                </FlexContainer>
                                            </FlexContainer>
                                        </div>

                                    </Card>

                                </FlexContainer>
                            )
                        })}
                    </FlexContainer>


                </FlexContainer>

                <Footer />
            </FlexContainer>

            <div style={{ background: `linear-gradient(to bottom, rgb(53, 55, 59), rgb(53, 55, 59) calc(100% - ${FOOTER_HEIGHT}px), transparent 100%)`, position: 'absolute', height: RADIUS * 2, width: RADIUS * 2, bottom: 0 - RADIUS * 2 + FOOTER_HEIGHT, borderRadius: '50%', zIndex: 0 }} />
        </FlexContainer>
    )
}

export default CashbackRender