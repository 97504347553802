import React, { useState, useEffect } from "react";

interface QuickTapDetectorProps {
  onFiveTaps: () => void;
  children: any
}

const QuickTapDetector: React.FC<QuickTapDetectorProps> = ({ onFiveTaps, children }) => {
  const [tapCount, setTapCount] = useState<number>(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const handleTap = () => {
    setTapCount((prevCount) => prevCount + 1);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      setTapCount(0); // reset tap count after a short delay
    }, 500); // 500ms window to register taps as "quick"

    setTimer(newTimer);

    if (tapCount + 1 === 5) {
      onFiveTaps(); // trigger action when 5 taps are detected
      setTapCount(0); // reset after triggering
    }
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return (
    <div
      onClick={handleTap}
    >
      {children}
    </div>
  );
};

export default QuickTapDetector;