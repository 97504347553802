import { Card, Progress, Table, Tag, Typography } from "@douyinfe/semi-ui"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import { useContext, useEffect, useState } from "react"
import { ISyncTask } from "../../../typings"
import { AppContext } from "../../../App"
import './index.less'
import { IconCrossCircleStroked, IconLoading, IconSpin, IconTick, IconTickCircle } from "@douyinfe/semi-icons"

import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'

dayjs.extend(relativeTime);
dayjs.extend(duration)

const SystemStatus = () => {
    const { windowHeight, navbarHeight } = useContext(AppContext)

    const [syncTaskList, setSyncTaskList] = useState<ISyncTask[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const handleFetch = async () => {
            setLoading(true)
            const respData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.sync_task ORDER BY sync_task_id DESC LIMIT 50`
                    ]
                }   
            })
    
            setSyncTaskList(respData)
            setLoading(false)
        }

        handleFetch()
        const interval = setInterval(handleFetch, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    const columns = [
        { title: 'ID', dataIndex: 'sync_task_id', width: 100 },
        { 
            title: 'Platform',
            width: 350,
            render: (record: ISyncTask) => {
                const showFromNow = dayjs(record.created_at).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') && record.progress !== record.total

                return (
                    <div>
                        <div>{record.source}</div>
                        <div style={{ fontSize: 10, color: 'grey' }}>
                            <div>Created at {dayjs(record.created_at).format('DD/MM HH:mm:ss')} {showFromNow ? `(${dayjs(record.created_at).fromNow()})` : ""}</div>
                            <div style={{ marginTop: -8 }}>Updated at {dayjs(record.updated_at).format('DD/MM HH:mm:ss')} {showFromNow ? `(${dayjs(record.updated_at).fromNow()})` : ""}</div>
                            {record.total === record.progress && <div style={{ marginTop: -8 }}>Duration: {dayjs(record.updated_at).from(dayjs(record.created_at), true)}</div>}
                        </div>
                    </div>
                )
            }
        },
        {
            title: ''
        },
        { 
            title: 'Status',
            width: 140,
            render: (record: ISyncTask) => {
                if (record.progress === record.total) {
                    return <Tag color="green" prefixIcon={<IconTickCircle />} style={{ height: 24 }}>Completed</Tag>
                }

                if (dayjs(record.updated_at).isBefore(dayjs().subtract(60, 'minute'))) {
                    return <Tag color="red" prefixIcon={<IconCrossCircleStroked />} style={{ height: 24 }}>Timeout</Tag>
                }

                return <Tag color="blue" prefixIcon={<IconSpin spin />} style={{ height: 24 }}>Syncing</Tag>
            }
        },
        { 
            title: 'Progress',
            width: 300,
            render: (record: ISyncTask) => {
                const percent = parseFloat((record.progress / record.total * 100).toFixed(1))

                return (
                    <Progress percent={percent} showInfo format={() => `${record.progress} / ${record.total}`} style={{ height: 8 }} />
                )
            }
        },
        { title: '', dataIndex: 'group_by', width: 120 }
    ]

    return (
        <div>
            <div className="font-weight-black responsive-text" style={{ padding: '8px 16px', fontSize: 20, letterSpacing: -0.5 }}>
                System Status
            </div>

            <Table
                size="small"
                empty={<span className="font-weight-regular">No data</span>}
                sticky
                loading={loading}
                dataSource={syncTaskList}
                columns={columns}
                pagination={false}
                style={{ height: windowHeight - navbarHeight - 46, overflow: 'auto' }}
            />
        </div>
    )
}

export default SystemStatus