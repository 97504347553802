import { Button, Descriptions, Form, SideSheet, Table, Tag, Toast } from "@douyinfe/semi-ui"
import { useContext, useEffect, useRef, useState } from "react"
import { IAirport, IFlightRoute } from "../../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import FlexContainer from "../../../components/FlexContainer"
import { IconSetting, IconSync } from "@douyinfe/semi-icons"
import copy from "copy-to-clipboard"
import { calcHaversineDistance, estimateFlightDuration } from "../../RewardFlightsDetail/utils"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import { AppContext } from "../../../App"
import dayjs from "dayjs"

const FlightRouteManagement = () => {

    const tableRef = useRef<any>()

    const { windowHeight, navbarHeight } = useContext(AppContext)

    const [pageLoading, setPageLoading] = useState(true)

    const [routeList, setRouteList] = useState<IFlightRoute[]>([])
    const [airportDict, setAirportDict] = useState<Record<string, IAirport>>({})

    const [selectedRoute, setSelectedRoute] = useState<IFlightRoute>()
    const [count, setCount] = useState(0)

    const formRef = useRef<FormApi>()

    useAsyncEffect(async () => {
        const airportListRespData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_airport`
                ]
            }
        })

        const routeListRespData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT a.*, economy_last_sync_at, business_last_sync_at
                    FROM simpo_octopus.au_airport_route a
                    LEFT JOIN (
                        SELECT route_key, MAX(created_at) AS economy_last_sync_at
                        FROM simpo_octopus.au_reward_seat
                        WHERE cabin_class = "economy"
                        GROUP BY route_key
                    ) b ON a.uniq_composite_key = b.route_key
                    LEFT JOIN (
                        SELECT route_key, MAX(created_at) AS business_last_sync_at
                        FROM simpo_octopus.au_reward_seat
                        WHERE cabin_class = "business"
                        GROUP BY route_key
                    ) c ON a.uniq_composite_key = c.route_key`
                ]
            }
        })

        setRouteList(routeListRespData)
        setAirportDict(Object.assign({}, ...airportListRespData.map((item: IAirport) => {
            return {
                [item.airport_code]: item
            }
        })))
        setCount(routeListRespData.length)
        setPageLoading(false)
    }, [])

    useEffect(() => {
        if (selectedRoute) {
            formRef.current?.setValues(selectedRoute)
        }
    }, [selectedRoute])

    const columns = [
        {
            title: 'Route key',
            dataIndex: 'uniq_composite_key',
            filterMultiple: false,
            filters: [
                {
                    text: 'Virgin Australia',
                    value: 'VA',
                },
                {
                    text: 'Qantas',
                    value: 'QF',
                },
            ],
            onFilter: (value: string, record: any) => {
                return record.uniq_composite_key.startsWith(value)
            },
            width: 130,
            render: (text: string) => {
                return (
                    <code style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => {
                        copy(text)
                        Toast.success('Copied to clipboard')
                    }}>{text}</code>
                )
            },
        },
        {
            title: 'Departure',
            width: 180,
            dataIndex: 'departure_airport_code',
            filterMultiple: false,
            filters: ["SYD", 'MEL', 'ADL', 'BNE', 'CNS', 'PER'].map(code => ({ text: code, value: code })),
            onFilter: (value: string, record: any) => {
                return record.departure_airport_code === value
            },
            render: (text: string, record: IFlightRoute) => {
                const airportDetail = airportDict[record.departure_airport_code]

                return (
                    <FlexContainer flexDirection="column">
                        <span>{airportDetail.airport_code}</span>
                        <span style={{ fontSize: 12, color: 'grey', marginTop: -4 }}>{airportDetail.city_name}, {airportDetail.country_name}</span>
                    </FlexContainer>
                )
            }
        },
        {
            title: 'Destination',
            dataIndex: 'destination_airport_code',
            filterMultiple: false,
            filters: Object.keys(airportDict).sort((a, b) => a.localeCompare(b)).map(code => ({ text: code, value: code })),
            onFilter: (value: string, record: any) => {
                return record.destination_airport_code === value
            },
            render: (text: string, record: IFlightRoute) => {
                const airportDetail = airportDict[record.destination_airport_code]

                return (
                    <FlexContainer flexDirection="column">
                        <span>{airportDetail.airport_code}</span>
                        <span style={{ fontSize: 12, color: 'grey', marginTop: -4 }}>{airportDetail.city_name}, {airportDetail.country_name}</span>
                    </FlexContainer>
                )
            }
        },
        {
            title: 'Trip detail',
            render: (record: IFlightRoute) => {
                const departureAirportDetail = airportDict[record.departure_airport_code]
                const destinationAirportDetail = airportDict[record.destination_airport_code]

                return (
                    <FlexContainer flexDirection="column">
                        <span>{calcHaversineDistance(departureAirportDetail, destinationAirportDetail)}</span>
                        <span style={{ fontSize: 12, color: 'grey', marginTop: -4 }}>{estimateFlightDuration(departureAirportDetail, destinationAirportDetail)}</span>
                    </FlexContainer>
                )
            }
        },
        {
            title: 'Last sync at',
            width: 250,
            dataIndex: 'economy_last_sync_at',
            sorter: (a: any, b: any) => {
                return dayjs(a.economy_last_sync_at || a.business_last_sync_at).unix() - dayjs(b.economy_last_sync_at || b.business_last_sync_at).unix()
            },
            render: (text: string, record: IFlightRoute) => {
                return (
                    <FlexContainer flexDirection="column" style={{ fontSize: 12 }} gap="6px">
                        <FlexContainer alignItems="center" gap="8px">
                            <div style={{ width: 70 }}><Tag color="blue">Economy</Tag></div>
                            {dayjs(record.economy_last_sync_at).fromNow()}
                        </FlexContainer>
                        <FlexContainer alignItems="center" gap="8px">
                            <div style={{ width: 70 }}><Tag color="green">Business</Tag> </div>
                            {dayjs(record.economy_last_sync_at).fromNow()}
                        </FlexContainer>
                    </FlexContainer>
                )
            }
        },
        {
            title: 'Action',
            width: 110,
            render: (record: IFlightRoute) => {
                return (
                    <FlexContainer gap="6px">
                        <Button theme='solid' style={{ borderRadius: 32 }} icon={<IconSync />} />

                        <Button type="tertiary" theme="solid" style={{ borderRadius: 32 }} icon={<IconSetting />} onClick={() => {
                            setSelectedRoute(record)
                        }} />
                    </FlexContainer>
                )
            }
        }
    ]

    return (
        <FlexContainer style={{ width: '100%' }}>
            <div style={{ flexGrow: 1, width: '100%' }}>
                <div className="font-weight-black responsive-text" style={{ fontSize: 20, letterSpacing: -0.5, padding: '8px 16px' }}>
                    Route Management ({count})
                </div>

                <Table
                    columns={columns}
                    dataSource={routeList}
                    pagination={{ pageSize: 50 }}
                    sticky
                    rowKey="uniq_composite_key"
                    loading={pageLoading}
                    style={{ height: windowHeight - navbarHeight - 46, overflow: 'auto' }}
                    ref={tableRef}
                    onChange={() => {
                        setTimeout(() => {
                            const el: any = document.querySelector('.semi-table-pagination-info')
                            if (el) {
                                const length = el.innerText?.split('of')?.slice(-1)?.[0]?.trim()
                                setCount(length)
                            }
                        }, 100)
                    }}
                />
            </div>

            <div style={{ width: 448, flexShrink: 0 }}></div>

            <SideSheet visible={!!selectedRoute} title="Flight route detail" onCancel={() => setSelectedRoute(undefined)} mask={false}>
                <Form getFormApi={api => formRef.current = api}>
                    <Form.Input field="uniq_composite_key" label="Route key" />
                    <Form.Switch field="enabled" label="Enabled?" />
                </Form>
                <Button theme="solid" onClick={() => {

                }}>Submit</Button>
            </SideSheet>
        </FlexContainer>
    )
}

export default FlightRouteManagement