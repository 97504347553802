import { parse } from "querystring"
import SimilarProductSidesheet from "../GroceriesByPlatform/components/SimilarProductSidesheet"
import SimilarDealSidesheet from "../DealsByPlatform/components/SimilarProductSidesheet"
import { ICashbackPromotion, IDeal } from "../../typings"
import { useEffect, useState } from "react"
import { useAsyncEffect } from "ahooks"
import { request, toBase64Unicode } from "../../utils"
import { Button, Spin, Toast, Typography } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"
import LoadingSpinner from "../../components/LoadingSpinner"
import CashbackDetail from "../CashbackDetail"
import { MerchantCashbackBody } from "../Cashback/components/MerchantCashbackSidesheet"

interface IEmbeddedProps {
    platform: string
    sku_id: string
}

interface IEmbeddedCashbackDetailProps {
    merchantName: string
}

const EmbeddedProductDetail = ({ platform, sku_id }: IEmbeddedProps) => {
    const [loading, setLoading] = useState(true)
    const [productDetail, setProductDetail] = useState<IDeal>()

    // useEffect(() => {
    //     setInterval(() => {
    //         console.log(window.location)
    //     }, 1000)
    // }, [])

    useAsyncEffect(async () => {
        const respData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT *, "${(platform as string).replace('_', '-')}" AS platform FROM simpo_octopus.au_${platform}_product_tmp WHERE sku_id = "${sku_id}"`
                ]
            }
        })

        setProductDetail(respData?.[0])
        setLoading(false)
    }, [])

    if (loading) {
        return (
            <FlexContainer justifyContent="center" alignItems="center" style={{ width: '100%', height: 400 }}>
                <FlexContainer flexDirection="column" alignItems="center">
                    <div style={{ width: 70, height: 70 }}><LoadingSpinner /></div>
                    <span className="font-weight-bold" style={{ fontWeight: 700, letterSpacing: -.5, fontSize: 20 }}>HotAuDeals</span>

                    <Typography.Paragraph type="tertiary" style={{ textAlign: 'center', lineHeight: 1.1, marginTop: 4 }}>
                        <span className="font-weight-regular" style={{ fontSize: 12 }}>
                            Fetching price history, <br /> please wait...
                        </span>
                    </Typography.Paragraph>
                </FlexContainer>
            </FlexContainer>
        )
    }

    return (
        <div style={{ background: 'white', height: '100%', overflow: 'auto', position: 'relative' }}>

            <FlexContainer flexDirection="column" alignItems="center" style={{ paddingBottom: 24 }}>
                {['coles', 'woolworths'].includes(platform as string) && (
                    <div>
                        <SimilarProductSidesheet mode="embedded" platform={productDetail?.platform} product={productDetail} />
                    </div>
                )}

                {['chemist_warehouse'].includes(platform as string) && (
                    <div>
                        <SimilarDealSidesheet mode="embedded" platform={productDetail?.platform} product={productDetail} />
                    </div>
                )}

                <div style={{ position: 'fixed', bottom: 12 }}>
                    <Button
                        theme="solid" type="tertiary" style={{ borderRadius: 30, background: 'rgb(53, 55, 59)', color: "white" }}
                        onClick={() => {
                            if (['coles', 'woolworths'].includes(platform as string)) {
                                window.open(`https://www.hotaudeals.com/groceries/${platform}?filterKey=${toBase64Unicode(JSON.stringify({ searchKeyword: sku_id }))}`)
                            } else if (['chemist_warehouse'].includes(platform as string)) {
                                window.open(`https://www.hotaudeals.com/deals/chemist-warehouse?filterKey=${toBase64Unicode(JSON.stringify({ searchKeyword: sku_id }))}`)
                            } else {
                                window.open('https://www.hotaudeals.com/')
                            }
                        }}
                    >
                        <FlexContainer alignItems="center">
                            <span className="font-weight-bold">View in</span>
                            <img src="https://img.icons8.com/color/18/fire-element--v1.png" style={{ marginLeft: 2, marginRight: 0 }} />
                            <span style={{ fontWeight: 700, letterSpacing: -.5 }}>HotAuDeals</span>
                        </FlexContainer>
                    </Button>
                </div>
            </FlexContainer>
        </div>
    )
}

const EmbeddedCashbackDetail = ({ merchantName }: IEmbeddedCashbackDetailProps) => {
    const [merchant, setMerchant] = useState<ICashbackPromotion>()

    useEffect(() => {
        const el: any = document.querySelector('.page-wrapper')

        if (el) {
            el.style.overflow = 'auto'

            return () => {
                el.style.overflow = 'hidden'
            }
        }
    }, [])

    useAsyncEffect(async () => {
        const respData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_cashback_promotion_tmp WHERE merchant_name = "${merchantName}"`
                ]
            }
        })

        setMerchant(respData?.[0])
    }, [merchantName])

    if (!merchant) {
        return <Spin spinning size="large" />
    }

    return (
        <MerchantCashbackBody merchant={merchant} />
    )
}

const EmbeddedWrapper = () => {
    const { platform, sku_id }: any = parse(window.location.search.slice(1))

    if (['coles', 'woolworths', 'chemist_warehouse'].includes(platform)) {
        return <EmbeddedProductDetail platform={platform} sku_id={sku_id} />
    }

    if (platform === 'cashback') {
        return <EmbeddedCashbackDetail merchantName={sku_id} />
    }
}

export default EmbeddedWrapper